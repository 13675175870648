import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import {Cube} from "../tabs/report"
class ImageSafety extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
    console.log("PROPS", this.props.data)
  }

  generateScore() {
    let score = Math.round(
      this.props.data.results.correct_safety.results.score * 100
    );

    this.props.generateScore(score);
  }

  render() {
    return (
      <>
        <Row className="mt-3 border-row">
          <Col xs="4" className="py-2">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.correct_safety.results.correct_adult
                  }
                ></Cube>
              </Col>
              <Col xs="10">
              Safety: Adult content
              </Col>
            </Row>
          </Col>
          <Col xs="6" className="py-2">
            <Row className="">
              <Col xs="6">
                <div>Adult</div>
              </Col>
              <Col xs="6">
                <div className="text-right">
                  {
                    this.props.data.results.correct_safety.results
                      .safety_number_adult
                  }
                  %
                </div>
              </Col>
              <Progress
                className="w-100 mx-3"
                value={
                  this.props.data.results.correct_safety.results
                    .safety_number_adult
                }
                color={
                  this.props.data.results.correct_safety.results
                    .safety_number_adult <
                  this.props.data.results.correct_safety.results.expected_adult
                    ? "success"
                    : "danger"
                }
              >
                {
                  this.props.data.results.correct_safety.results
                    .safety_number_adult
                }
                %
              </Progress>
            </Row>
          </Col>
          <Col xs="2" className="py-2">
          {this.props.data.results.correct_safety.results
                  .correct_adult ? (
                  <div>{this.props.data.results.correct_safety.results.reco_pos_adult}</div>
                ) : (
                  <div>{this.props.data.results.correct_safety.results.reco_neg_adult}</div>
                )}
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-2">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.correct_safety.results
                      .correct_medical
                  }
                ></Cube>
              </Col>
              <Col xs="10">
              Safety: Medical content
              </Col>
            </Row>
          </Col>
          <Col xs="6" className="py-2">
            <Row>
              <Col xs="6">
                <div>Medical</div>
              </Col>
              <Col xs="6">
                <div className="text-right">
                  {
                    this.props.data.results.correct_safety.results
                      .safety_number_medical
                  }
                  %
                </div>
              </Col>
              <Progress
                className="w-100 mx-3"
                value={
                  this.props.data.results.correct_safety.results
                    .safety_number_medical
                }
                color={
                  this.props.data.results.correct_safety.results
                    .safety_number_medical <
                  this.props.data.results.correct_safety.results
                    .expected_medical
                    ? "success"
                    : "danger"
                }
              >
                {
                  this.props.data.results.correct_safety.results
                    .safety_number_medical
                }
                %
              </Progress>
            </Row>
          </Col>
          <Col xs="2" className="py-2">
          {this.props.data.results.correct_safety.results
                  .correct_medical ? (
                  <div>{this.props.data.results.correct_safety.results.reco_pos_medical}</div>
                ) : (
                  <div>{this.props.data.results.correct_safety.results.reco_neg_medical}</div>
                )}
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-2">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.correct_safety.results.correct_racy
                  }
                ></Cube>
              </Col>
              <Col xs="10">
              Safety: Racy content
              </Col>
            </Row>
          </Col>
          <Col xs="6" className="py-2">
            <Row>
              <Col xs="6">
                <div>Racy</div>
              </Col>
              <Col xs="6">
                <div className="text-right">
                  {
                    this.props.data.results.correct_safety.results
                      .safety_number_racy
                  }
                  %
                </div>
              </Col>
              <Progress
                className="w-100 mx-3"
                value={
                  this.props.data.results.correct_safety.results
                    .safety_number_racy
                }
                color={
                  this.props.data.results.correct_safety.results
                    .safety_number_racy <
                  this.props.data.results.correct_safety.results.expected_racy
                    ? "success"
                    : "danger"
                }
              >
                {
                  this.props.data.results.correct_safety.results
                    .safety_number_racy
                }
                %
              </Progress>
            </Row>
          </Col>
          <Col xs="2" className="py-2">
          {this.props.data.results.correct_safety.results.correct_racy ? (
                  <div>{this.props.data.results.correct_safety.results.reco_pos_racy}</div>
                ) : (
                  <div>{this.props.data.results.correct_safety.results.reco_neg_racy}</div>
                )}
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-2">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.correct_safety.results
                      .correct_spoofed
                  }
                ></Cube>
              </Col>
              <Col xs="10">
              Safety: Spoofed content
              </Col>
            </Row>
          </Col>
          <Col xs="6" className="py-2">
            <Row>
              <Col xs="6">
                <div>Spoofed</div>
              </Col>
              <Col xs="6">
                <div className="text-right">
                  {
                    this.props.data.results.correct_safety.results
                      .safety_number_spoofed
                  }
                  %
                </div>
              </Col>
              <Progress
                className="w-100 mx-3"
                value={
                  this.props.data.results.correct_safety.results
                    .safety_number_spoofed
                }
                color={
                  this.props.data.results.correct_safety.results
                    .safety_number_spoofed <
                  this.props.data.results.correct_safety.results
                    .expected_spoofed
                    ? "success"
                    : "danger"
                }
              >
                {
                  this.props.data.results.correct_safety.results
                    .safety_number_spoofed
                }
                %
              </Progress>
            </Row>
          </Col>
          <Col xs="2" className="py-2">
          {this.props.data.results.correct_safety.results
                  .correct_spoofed ? (
                  <div>{this.props.data.results.correct_safety.results.reco_pos_spoofed}</div>
                ) : (
                  <div>{this.props.data.results.correct_safety.results.reco_neg_spoofed}</div>
                )}
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-2">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.correct_safety.results
                      .correct_violence
                  }
                ></Cube>
              </Col>
              <Col xs="10">
              Safety: Violent content
              </Col>
            </Row>
          </Col>
          <Col xs="6" className="py-2">
            <Row>
              <Col xs="6">
                <div>Violent</div>
              </Col>
              <Col xs="6">
                <div className="text-right">
                  {
                    this.props.data.results.correct_safety.results
                      .safety_number_violence
                  }
                  %
                </div>
              </Col>
              <Progress
                className="w-100 mx-3"
                value={
                  this.props.data.results.correct_safety.results
                    .safety_number_violence
                }
                color={
                  this.props.data.results.correct_safety.results
                    .safety_number_violence <
                  this.props.data.results.correct_safety.results
                    .expected_violence
                    ? "success"
                    : "danger"
                }
              >
                {
                  this.props.data.results.correct_safety.results
                    .safety_number_violence
                }
                %
              </Progress>
            </Row>
          </Col>
          <Col xs="2" className="py-2">
          {this.props.data.results.correct_safety.results
                  .correct_violence ? (
                  <div> {this.props.data.results.correct_safety.results.reco_pos_violence}</div>
                ) : (
                  <div> {this.props.data.results.correct_safety.results.reco_neg_violence}</div>
                )}
          </Col>
        </Row>
      </>
    );
  }
}


export default ImageSafety;
