import React from "react";

import axios from "axios";
import { Card, CardBody } from "reactstrap";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import moment from "moment";
import SubActions from "./utils";

class Explorer extends React.Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.state = {
      tasks: [],
      filtered:[]
    };

    this.columns = (token) => [
      {
        name: "Image",
        cell: (row) => <img src={`/platform/attachments/servethumb?id=${row._id}&token=${token}`}></img>,
        sortable: false,
        center: true,
      },
      {
        name: "Label",
        selector: "label",
        sortable: true,
        center: true,
      },
      {
        name: "Date",
        selector: (row) => moment(row.creation).unix(),
        cell: (row) => moment(row.creation).format("lll"),
        sortable: true,
        center: true,
      },
      {
        name: "User",
        selector: (row) => row.owner.full_name,
        sortable: true,
        center: true,
      },
      {
        name: "Project/Team",
        selector: (row) => row.project.name + " / " + row.project.team.name,
        sortable: true,
        center: true,
      },
      {
        name: "Task",
        selector: (row) => {
          return row.tasks.map(task => task + " ")
        },
        sortable: true,
        center: true,
      },
      {
        name: "Media",
        selector: "media",
        sortable: true,
        center: true,
      },
      {
        name: "Method",
        selector: "method",
        sortable: true,
        center: true,
      },
      {
        name: "Type",
        selector: "type",
        sortable: true,
        center: true,
      },
      {
        name: "Industry",
        selector: "industry",
        sortable: true,
        center: true,
      },
      {
        name: "Geography",
        selector: "geography",
        sortable: true,
        center: true,
      },
      {
        name: "Channel",
        selector: "channel",
        sortable: true,
        center: true,
      },
    ];
  }

  search(event) {
    var keyword = event.target.value;

    var filteredData = this.state.tasks.filter(function (obj) {
      return (
        obj["label"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["industry"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["media"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["method"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["geography"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["channel"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["type"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["owner"]["full_name"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["project"]["name"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["project"]["team"]["name"].toLowerCase().includes(keyword.toLowerCase()) 
      );
    });
    this.setState({ filtered: filteredData });
  }


  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Explorer", "database"));
    console.log( (new Date()))
    axios
      .get("/platform/admin/explorer/get", { params: { year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1 } })
      .then((res) => {
        this.setState({ tasks: res.data.tasks, filtered: res.data.tasks });
      })
      .catch((err) => {
        console.log(err);
            this.props.history.push("/home");
      });
  }

  render() {
    return (
      <div class="content">
        <Helmet>
          <title>CRISP - Explorer</title>
        </Helmet>
        <div className="w-100 text-right"></div>
        <Card>
          <CardBody>
            <DataTable
              title="Tasks"
              pagination={true}
              columns={this.columns(this.props.resource_token.resource_token)}
              data={this.state.filtered}
              subHeader={true}
              striped={true}
              className="clickable-rows"
              onRowClicked={(row) =>
                this.props.history.push("/tasks/" + row._id)
              }
              subHeaderComponent={
                <SubActions value={this.state.value} onChange={this.search} />
              }
            ></DataTable>
          </CardBody>
        </Card>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
});



export default connect(mapStateToProps)(Explorer);
