import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import titleReducer from "./titleReducer";
import resourceReducer from "./resourceReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  title: titleReducer,
  resource_token: resourceReducer,
});
