import React from "react";

import axios from "axios";
import {
    Badge,
  Button,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import SubActions from "../utils"
import DataTable from "react-data-table-component";
import moment from "moment";
import { connect } from "react-redux";
import { setPageTitle } from "../../../actions/titleActions";
import { Helmet } from "react-helmet";
import DropzoneRaw from "./Dropzone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Project extends React.Component {
  constructor(props) {

    super(props);
    this.project = this.props.match.params.id;
    this.search = this.search.bind(this);
    this.status = this.status.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      users: [],
      filtered: [],
      selected:[],
    };

    this.columns = (token) => [
        {
            name: "Thumbnail",
            cell: (row) => <img className="img-sm" src={`/platform/attachments/servethumbraw?id=${row._id}&token=${token}`}></img>,
            sortable: false,
            center: true,
          },
      {
        name: "Name",
        selector: (row) => row.label,

        sortable: true,
        center: true,
      },
      {
        name: "Modules",
        cell: (row) => {
            var el = []
            for(var i = 0 ; i < row.modules.length; i++) {
                var new_string = row.modules[i].replace(row.media, ''); 
                el.push(<Badge className="ml-2 mt-1" color="secondary">{new_string}</Badge>)
            }
            return <Row className="text-center justify-content-center">{el}</Row>
        },
        sortable: false,
        center: true,
      },
      {
        name: "Date",
        selector: (row) => moment(row.creation).unix(),
        cell: (row) => moment(row.creation).format("lll"),
        sortable: true,
        center: true,
      },
      {
        name: "Media",
        selector: "media",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        cell: (row) => {
            if(row.status == "finished"){
                return (<Badge color="success">DONE</Badge>)
            } else   if(row.status == "error"){
                return (<Badge color="danger">ERROR</Badge>)
            }
            else if(row.status == "pending"){
              if(row.progress) {
                return (<Badge color="warning">{row.progress ? row.progress : "0/0"}</Badge>)
              } else {
                return (<Badge color="warning">PENDING</Badge>)
              }
            }
            
         },
        sortable: true,
        center: true,
      },
    ];
  }

  componentWillUnmount(){
    clearTimeout(this.timeout);
  }

  handleSelect(state){
    
      this.setState({selected:state.selectedRows.map(a => a._id)});
  }


  async status() {
    var er = false;
    var pending_tasks = this.state.tasks.filter((task) => {
      return task.status === "pending";
    });
    if (pending_tasks.length === 0) {
      return
    }

      await Promise.all(pending_tasks.map(async (task) => {
        try {
        var res = await axios
          .get("/platform/admin/raws/status", {
            params: {
              id: task._id,
            },
          })

          this.setState((prevState) => {
            return {
              tasks: prevState.tasks.map((el) =>
                el._id === task._id ? { ...el, status: res.data.status, progress: res.data.progress} : el
              ),
              filtered: prevState.filtered.map((el) =>
              el._id === task._id ? { ...el, status: res.data.status, progress: res.data.progress} : el
            ),
            };
    
        })
      } catch(err) {
        this.setState((prevState) => {
          return {
            tasks: prevState.tasks.map((el) =>
              el._id === task._id ? { ...el, status: "error"} : el
            ),
            filtered: prevState.filtered.map((el) =>
            el._id === task._id ? { ...el, status: "error"} : el
          ),
          };
      });
      }
      }));

this.timeout = setTimeout(() => this.status(), 2000)
  }

  search(event) {
    var keyword = event.target.value;
    var filteredData = this.state.users.filter(function (obj) {
      return (
        obj["label"].toLowerCase().includes(keyword.toLowerCase()) ||
        obj["modules"].toLowerCase().includes(keyword.toLowerCase())
      );
    });
    this.setState({ filtered: filteredData });
  }

  delete() {
    axios
    .post("/platform/admin/raws/deleteraws", 
      { ids: this.state.selected }
    )
    .then(function (response) {
      window.location.reload()
    });
  }

  export() {

  axios
  .get("/platform/admin/raws/export", {
    responseType: "blob",
    params: { ids: this.state.selected },
  })
  .then(function (response) {
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(response.data);
    link.download =  "export.zip";
    link.click();
  });
}

  componentDidMount() {
    const { dispatch } = this.props;
  
    axios
      .get("/platform/admin/raws/gettasksperproject", {params: {id: this.project}})
      .then((res) => {
     
        this.setState({ tasks: res.data.tasks, filtered: res.data.tasks });
        dispatch(setPageTitle(res.data.name, "folder"));
        this.status()
      })
      .catch((err) => {
        console.log(err);
            // this.props.history.push("/home");
      });
  }

  render() {
    return (
      <div class="content">
         <FontAwesomeIcon
          icon="arrow-left"
          className="prev clickable"
          onClick={() => this.props.history.push("/admin/raws")}
        ></FontAwesomeIcon>
        <DropzoneRaw
          ref={(modal) => {
            this.dz = modal;
          }}
          project={this.project}
        ></DropzoneRaw>
        <div className="w-100 text-right">
        <Button disabled={this.state.selected.length == 0} onClick={() => this.export()}   color="secondary">
            Export
          </Button>
          <Button disabled={this.state.selected.length == 0} onClick={() => this.delete()}   color="danger">
            Delete
          </Button>
          <Button onClick={() => this.dz.toggle(this.project)} color="primary">
            Create tasks
          </Button>
          </div>
        <Helmet>
           
          <title>CRISP - Raws</title>
        </Helmet>
        <div className="w-100 text-right"></div>
        <Card>
          <CardBody>
            <DataTable
              title="Projects"
              pagination={true}
              columns={this.columns(this.props.resource_token.resource_token)}
              data={this.state.filtered}
              subHeader={true}
              striped={true}
              selectableRows 
              className="clickable-rows"
              onRowClicked={(row) =>
                this.props.history.push("/admin/raws/tasks/" + row._id)
              }
              onSelectedRowsChange={this.handleSelect}
              subHeaderComponent={
                <SubActions value={this.state.value} onChange={this.search} />
              }
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}




const mapStateToProps = (state) => ({
    resource_token: state.resource_token,
  });
  
  
  
  export default connect(mapStateToProps)(Project);