import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import IndexTeam from "./teams/Index";
import IndexUser from "./users/Index";
import ViewTeam from "./teams/View";
import ViewUser from "./users/View";
import LogsIndex from "./Logs";
import Explorer from "./Explorer";
import Feedbacks from "./Feedbacks";
import IndexProjects from "./raws/IndexProjects";
import Project from "./raws/Project";
import RawTask from "./raws/Task";
class AdminLayout extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/dashboard" component={Dashboard} />
        <Route exact path="/admin/teams" component={IndexTeam} />
        <Route exact path="/admin/teams/:id" component={ViewTeam} />
        <Route exact path="/admin/users" component={IndexUser} />
        <Route exact path="/admin/users/:id" component={ViewUser} />
        <Route exact path="/admin/logs" component={LogsIndex} />
        <Route exact path="/admin/explorer" component={Explorer} />
        <Route exact path="/admin/feedbacks" component={Feedbacks} />
        <Route exact path="/admin/raws" component={IndexProjects} />
        <Route exact path="/admin/raws/projects/:id" component={Project} />
        <Route exact path="/admin/raws/tasks/:id" component={RawTask} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    );
  }
}

AdminLayout.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminLayout);
