import React, { useEffect, useState } from "react";
import moment from "moment";
import striptags from "striptags";
import axios from "axios";
import { Row, Col, Card, CardImg, CardBody, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tasks_mapping from "../../variables/access.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import industries from "../../variables/industries.json";
import geographies from "../../variables/geographies.json";
import channels from "../../variables/channels.json";
import types from "../../variables/types.json";

const TaskCard = (props) => {
  const [task, setTask] = useState(
    props.task
      ? {
          ...props.task,
          label: props.task.label,
          crop_name:
            props.task.label.length > 18
              ? props.task.label.substring(0, 18) + "..."
              : props.task.label,
        }
      : false
  );
  var methods = {
    url: { icon: "globe", pretty: "URL" },
    chrome: { icon: "chrome", pretty: "Google Chrome" },
    upload: { icon: "upload", pretty: "Upload" },
    api: { icon: "server", pretty: "API" },
    adobe: { icon: "adobe", pretty: "Adobe" },
  };

  var media = {
    image: { icon: "image", pretty: "Image" },
    dom: { icon: "file-code", pretty: "HTML content" },
    video: { icon: "video", pretty: "Video" },
  };

  var timeout = false;

  console.log(task)

  const download = () => {
    axios
      .get("/platform/tasks/download", {
        responseType: "blob",
        params: { id: task._id },
      })
      .then(function (response) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(response.data);
        link.download = striptags(task.label) + ".zip";
        link.click();
      });
  };

  useEffect(() => {
    status();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [props.task]);

  const status = () => {
    if (task.status == "pending") {
      axios
        .get("/platform/tasks/status", {
          params: {
            id: task._id,
          },
        })
        .then((res) => {
          setTask({ ...task, status: res.data.status });
          timeout = setTimeout(() => status(), 2000);
        })
        .catch((err) => {
          setTask({ ...task, status: "error" });
        });
    }
  };

  return task !== false ? (
    <Col xs="19">
      <Card className={"fade-in task-" + task.status}>
        <CardBody>
          <div className="container-icon-task ">
            <div className="container-icon-task ">
              {/* <FontAwesomeIcon
                className={"method-icon " + task.method}
                icon={methods[task.method].icon}
                title={methods[task.method].pretty}
              ></FontAwesomeIcon> */}

              <FontAwesomeIcon
                className={"ml-2 method-icon"}
                icon={media[task.media].icon}
                title={media[task.media].pretty}
              ></FontAwesomeIcon>
            </div>
            {props.noedit ? (
              <span></span>
            ) : (
              <div className="pull-right h-100">
                <FontAwesomeIcon
                  onClick={() => props.editRef.toggle(task, task.project.team)}
                  className="icon-card clickable mr-2"
                  icon="edit"
                  title="Edit task"
                />
                {/* <FontAwesomeIcon
                onClick={() => download()}
                className="icon-card clickable mr-2"
                icon="download"
              /> */}
                {/* <FontAwesomeIcon
                  onClick={() => props.shareRef.toggle(task._id)}
                  className="icon-card clickable mr-2"
                  icon="share"
                /> */}
                <FontAwesomeIcon
                  onClick={() => props.deleteRef.toggle(task._id)}
                  className="icon-card clickable"
                  icon="trash"
                  title="Delete task"
                />
              </div>
            )}
          </div>

          <div className="mb-3 mt-3">
            <span className="bold project-name">
              <a
                href={`/projects/${task.project._id}`}
              >{`${task.project.name}`}</a>{" "}
              <span className="chev">{" > "}</span>{" "}
            </span>
            <span className="task-title">
              {task.method == "url" ? (
                <a target="_blank" className="quiet-a" href={task.url}>
                  {task.url.split("/")[2]}
                </a>
              ) : (
                task.crop_name
              )}
            </span>
          </div>

          <div className="mt-2 text-center">
            {Object.keys(tasks_mapping).map((key) => {
              var badge = tasks_mapping[key];
              return badge.available_for.indexOf(task.media) !== -1 ? (
                <Badge
                  className={
                    "mr-2 mt-1 badge-task " +
                    (task.reports.indexOf(key) !== -1 ? "active" : "")
                  }
                >
                  {badge.badge}
                </Badge>
              ) : (
                <span></span>
              );
            })}
          </div>
          <div className="date-card mt-3">
            {" "}
            Created {moment(task.creation).format("lll")} by{" "}
            {task.owner.full_name}{" "}
          </div>
        </CardBody>
        <div style={{ position: "relative" }}>
          {task.status === "pending" ? (
            <FontAwesomeIcon
              className="spinner-rotate task-spinner"
              icon="spinner"
            ></FontAwesomeIcon>
          ) : task.status === "error" ? (
            <span className="task-error-icon-container">
              <FontAwesomeIcon
                className="task-error-icon"
                icon="exclamation-circle"
              ></FontAwesomeIcon>
              <br></br>
              <span>An error occured</span>
            </span>
          ) : (
            <span></span>
          )}

          <div>
            <div
              onMouseEnter={() => {
                document
                  .getElementById(`info-${task._id}`)
                  .classList.remove("info-out");
                document
                  .getElementById(`info-${task._id}`)
                  .classList.add("info-in");
              }}
              onMouseLeave={() => {
                document
                  .getElementById(`info-${task._id}`)
                  .classList.remove("info-in");
                document
                  .getElementById(`info-${task._id}`)
                  .classList.add("info-out");
              }}
              id={`info-${task._id}`}
              className="info-task"
            >
              <FontAwesomeIcon icon="info"></FontAwesomeIcon>
              <Row>
                <Col xs={6}>
                  <span class="title-info-task"> Type</span> <br></br>
                  {task.type != "" &&
                  types.filter((row) => row.name === task.type)[0] !==
                    undefined ? (
                    types.filter((row) => row.name === task.type)[0].pretty_name
                  ) : (
                    <span className="not-specified">Not specified</span>
                  )}
                  <hr></hr>
                  <span class="title-info-task">Industry</span>
                  <br></br>
                  {task.industry != "" &&
                  industries.filter((row) => row.name === task.industry)[0] !==
                    undefined ? (
                    industries.filter((row) => row.name === task.industry)[0]
                      .pretty_name
                  ) : (
                    <span className="not-specified"> Not specified</span>
                  )}
                </Col>
                <Col xs={6} className="text-right bordered-col">
                  <span class="title-info-task"> Geography</span>
                  <br></br>
                  {task.geography != "" &&
                  geographies.filter(
                    (row) => row.name === task.geography
                  )[0] !== undefined ? (
                    geographies.filter((row) => row.name === task.geography)[0]
                      .pretty_name
                  ) : (
                    <span className="not-specified"> Not specified</span>
                  )}
                  <hr></hr>
                  <span class="title-info-task"> Channel</span> <br></br>
                  {task.channel != "" &&
                  channels.filter((row) => row.name === task.channel)[0] !==
                    undefined ? (
                    channels.filter((row) => row.name === task.channel)[0]
                      .pretty_name
                  ) : (
                    <span className="not-specified"> Not specified</span>
                  )}
                </Col>
              </Row>
            </div>

            <a
              className={
                "cardimg-task-container" +
                (task.status !== "finished" ? "disabled" : "")
              }
              onClick={() => {
                if (task.status === "finished") {
                  props.history.push("/tasks/" + task._id);
                }
              }}
              href={"/tasks/" + task._id}
            >
              {task.media === "video" ? (
                <span>
                  {" "}
                  <CardImg
                    top
                    width="100%"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/img/loading.gif";
                    }}
                    className={"card-img-video "}
                    src="https://crisp.rd.center/images/player.png"
                    alt="Card image cap"
                  />
                  <LazyLoadImage
                    placeholderSrc={
                      "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/img/loading.gif";
                    }}
                    effect="blur"
                    className="card-img-results"
                    src={`/platform/attachments/servethumb?id=${task._id}&token=${props.token}`}
                  />
                </span>
              ) : (
                <LazyLoadImage
                  placeholderSrc={
                    "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/img/loading.gif";
                  }}
                  effect="blur"
                  className="card-img-results"
                  src={`/platform/attachments/servethumb?id=${task._id}&token=${props.token}`}
                />
              )}
            </a>
          </div>
        </div>
      </Card>
    </Col>
  ) : (
    <span></span>
  );
};

export default TaskCard;
