import React from "react";

import axios from "axios";
import {
  Card,
  CardBody,
} from "reactstrap";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import Logs from "../log/Log";

class LogsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: []
    };

  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Logs", "file-alt"));
    axios
      .get("/platform/admin/logs/getall")
      .then((res) => {
        this.setState({ logs: res.data.logs });
      })
      .catch((err) => {
        console.log(err);
            this.props.history.push("/home");
      });
  }

  render() {
    return (
      <div class="content">
        <Helmet>
          <title>CRISP - Logs</title>
        </Helmet>
        <div className="w-100 text-right"></div>
        <Card>
          <CardBody>
                <Logs logs={this.state.logs}></Logs>
          </CardBody>
        </Card>
      </div>
    );
  }
}



export default connect()(LogsIndex);
