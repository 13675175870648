import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import {Cube} from "../tabs/report"
class ImageColorblindAccess extends React.Component {
  constructor(props) {
    super(props);

  }

 

  render() {
    return (
      <>
        <Row className="border-row no-top-border">
          <Col className="py-2" xs="4">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data?.results?.correct_average_score.results.score
                      .averageScoreLarge <=
                    this.props.data?.results?.correct_average_score.results.score
                      .deuteranopia.averageScoreLarge
                      
                  }
                ></Cube>
              </Col>
              <Col xs="10">Visibility</Col>
            </Row>
          </Col>
          <Col className="py-2 " xs="4">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data?.results?.correct_average_score.results.score
                      .averageScoreLarge <=
                    this.props.data?.results?.correct_average_score.results.score
                      .protanopia.averageScoreLarge
                  }
                ></Cube>
              </Col>
              <Col xs="10">Visibility</Col>
            </Row>
          </Col>
          <Col className="py-2" xs="4">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data?.results?.correct_average_score.results.score
                      .averageScoreLarge <=
                    this.props.data?.results?.correct_average_score.results.score
                      .tritanopia.averageScoreLarge
                  }
                ></Cube>
              </Col>
              <Col xs="10">Visibility</Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}



export default ImageColorblindAccess;
