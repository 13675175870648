import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
  Button,
} from "reactstrap";
import axios from "axios";
import industries from "../../../variables/industries.json";
import geographies from "../../../variables/geographies.json";
import channels from "../../../variables/channels.json";
import types from "../../../variables/types.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorJSON from "../../utils/Error";
import ImageMemorability from "./../blocks/ImageMemorability";
import ImageColors from "./../blocks/ImageColors";
import ImageObjects from "./../blocks/ImageObjects";
import ImageEmotions from "./../blocks/ImageEmotions";
import ImageFormats from "./../blocks/ImageFormats";
import ImageSafety from "./../blocks/ImageSafety";
import ImageLogos from "./../blocks/ImageLogos";
import ImageColorblind from "./../blocks/ImageColorblind";
import ImageAccessibility from "./../blocks/ImageAccessibility";
import ImageCopywriting from "./../blocks/ImageCopywriting";
import ImageLabels from "./../blocks/ImageLabels";
import ImagePerson from "./../blocks/ImagePerson";
import ImageTextRatio from "./../blocks/ImageTextRatio";
import ImageColorblindAccess from "./../blocks/ImageColorblindAccess";
import Score from "./scoring";
import SampleNotImplemented from "../blocks/SampleNotImplemented";
import EditBlock from "../blocks/EditBlock";
import DomNavigation from "../blocks/DomNavigation";
import DomLogo from "../blocks/DomLogo";
import DomRendering from "../blocks/DomRendering";
import DomImage from "../blocks/DomImage";
import DomCTA from "../blocks/DomCTA";
import DomText from "../blocks/DomText";
import DomForm from "../blocks/DomForm";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class ReportTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreFormat: 0,
      scoreAccessibility: 0,
      scoreCopywriting: 0,
      scoreEmotion: 0,
      scoreMemorability: 0,
      scoreTextRatio: 0,
      scoreSafety: 0,
      scoreDomCTA: 0,
      scoreDomText: 0,
      scoreDomNavigation: 0,
    };

    this.getScoreFormat = this.getScoreFormat.bind(this);
    this.getScoreSafety = this.getScoreSafety.bind(this);
    this.getScoreAccessibility = this.getScoreAccessibility.bind(this);
    this.getScoreCopywriting = this.getScoreCopywriting.bind(this);
    this.getScoreEmotion = this.getScoreEmotion.bind(this);
    this.getScoreMemorability = this.getScoreMemorability.bind(this);
    this.getScoreTextRatio = this.getScoreTextRatio.bind(this);
    this.getScoreDomCTA = this.getScoreDomCTA.bind(this);
    this.getScoreDomText = this.getScoreDomText.bind(this);
    this.getScoreDomNavigation = this.getScoreDomNavigation.bind(this);
  }

  getScoreFormat(score) {
    this.setState({ scoreFormat: score });
  }

  getScoreTextRatio(score) {
    this.setState({ scoreTextRatio: score });
  }

  getScoreAccessibility(score) {
    this.setState({ scoreAccessibility: score });
  }

  getScoreCopywriting(score) {
    this.setState({ scoreCopywriting: score });
  }

  getScoreEmotion(score) {
    this.setState({ scoreEmotion: score });
  }

  getScoreMemorability(score) {
    this.setState({ scoreMemorability: score });
  }

  getScoreSafety(score) {
    this.setState({ scoreSafety: score });
  }

  getScoreDomCTA(score) {
    this.setState({ scoreDomCTA: score });
  }

  getScoreDomText(score) {
    this.setState({ scoreDomText: score });
  }

  getScoreDomNavigation(score) {
    this.setState({ scoreDomNavigation: score });
  }

  reprocess(todo) {
    var task = this.props.task;
    console.log("todo,", todo);
    console.log("task,", task);

    if (todo === "mcu" && (task.industry === "" || task.type === "")) {
      this.setState({
        submitted: false,
        errors:
          "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }

    if(todo === "mcu" && task.reports.indexOf("memorability") === -1){
      todo = ["mcu", "memorability"]
    } else {
      todo = [todo]
    }

    axios
      .post("/platform/tasks/edit", {
        ...task,
        tasks: task.reports.concat(todo),
        id: task._id,
      })
      .then((res) => {
        console.log("this.props",this.props)
        this.props.history.push("/projects/" + task.project._id);
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    var access = this.props.access;
    console.log("TASK", task);

    return (
      <>
        {!task.analysis ? (
          <span></span>
        ) : (
          <>
            <EditBlock
              ref={(modal) => {
                this.editRef = modal;
              }}
              id={task._id}
            ></EditBlock>
            {console.log("task", task)}
            {!access.includes("mcu") ||
            task.reports.indexOf("mcu") === -1 ? (
              <div className=" px-4">
                <CheckBlockPresence analysis={task.analysis} block="vco">
                  <Row>
                     
                    <Col xs="12 fade-in" className="disabled-grayscale">
                      <Row>
                     
                        <div className="text-center mt-3 mb-3 w-100">
                          {task.media === "video" ? (
                            <h3 className="warning">
                              This functionnality is not available for videos
                              yet, here is a sample for an image:
                            </h3>
                          ) : !access.includes("mcu") ? (
                            <h3 className="warning">
                              This functionnality is not available in your plan,
                              here is a sample report. To get access, please
                              contact{" "}
                              <a href="mailto:crisp.team@accenture.com">
                                crisp.team@accenture.com
                              </a>
                              . Thank you!
                            </h3>
                          ) : (
                            <div>
                              {this.state.errors ? (
                                <ErrorJSON json={this.state.errors}></ErrorJSON>
                              ) : (
                                <span></span>
                              )}{" "}
                              <h3 className="success">
                                This functionnality is available. <br></br>{" "}
                                Click on the button to request a Creativity Report for this
                                content. <br></br>{" "}
                              </h3>
                              <Button
                                onClick={() => this.reprocess("mcu")}
                                color="danger"
                              >
                                PROCESS
                              </Button>
                            </div>
                          )}
                        </div>
                      </Row>
                      <Row>
                        <img
                          top
                          width="100%"
                          className="card-img-results "
                          src={"/img/creativity-sample-cropped.png"}
                          alt="Card image cap"
                        />
                      </Row>
                    </Col>
                  </Row>
                </CheckBlockPresence>
              </div>
            ) : (
              <div>
                <Row className="px-4">
                  <Col className="mt-4 sticky-menu" xs="2">
            
                    <Row>
                      <Col className="category-link">Overview</Col>
                    </Row>
                    <hr class="solid"></hr>
                    <Row>
                      <Col className="category-link">
                        <a className="normal-a" href="#format">
                          Format
                        </a>
                      </Col>
                    </Row>
                    <hr class="solid"></hr>
                    <Row>
                      <Col className="category-link">
                        <a className="normal-a" href="#composition">
                          Composition
                        </a>
                      </Col>
                    </Row>
                    <hr class="solid"></hr>
                    <Row>
                      <Col className="category-link">
                        <a className="normal-a" href="#usability">
                          Usability
                        </a>
                      </Col>
                    </Row>
                    <hr class="solid"></hr>
                    <Row>
                      <Col className="category-link">
                        <a className="normal-a" href="#message">
                          Message
                        </a>
                      </Col>
                    </Row>
                    <hr class="solid"></hr>
                    {/* <Row>
                        <Col className="category-link">Raw Data</Col>
                      </Row>
                      <hr class="solid"></hr> */}
                  </Col>

                  {/* Score rounds */}
                  <Col className="mt-4" xs="10">
                    <Score task={task} state={this.state}></Score>

                    {/* End Score rounds */}

                    {/* Format */}

                    <CheckBlockPresence
                      analysis={task.analysis}
                      block="image_format"
                    >
                      <ImageFormats
                        id={task._id}
                        data={task.analysis.image_format}
                        token={token}
                        generateScore={this.getScoreFormat}
                      ></ImageFormats>
                    </CheckBlockPresence>

                    {/* Composition */}
                    <Row id="composition" className="mt-4">
                      <Col xs="12 fade-in">
                        <Row>
                          <div className="category-text">COMPOSITION</div>
                        </Row>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="notimplemented"
                        >
                          <SampleNotImplemented
                            id={task._id}
                            data={task.analysis.notimplemented}
                            token={token}
                            editRef={this.editRef}
                          ></SampleNotImplemented>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="dom_navigation"
                        >
                          <DomNavigation
                            id={task._id}
                            data={task.analysis.dom_navigation}
                            token={token}
                            generateScore={this.getScoreDomNavigation}
                          ></DomNavigation>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="dom_logo"
                        >
                          <DomLogo
                            id={task._id}
                            data={task.analysis.dom_logo}
                            token={token}
                          ></DomLogo>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_object"
                        >
                          <ImageObjects
                            id={task._id}
                            data={task.analysis.image_object}
                            token={token}
                          ></ImageObjects>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_logo"
                        >
                          <ImageLogos
                            id={task._id}
                            data={task.analysis.image_logo}
                            token={token}
                          ></ImageLogos>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_label"
                        >
                          <ImageLabels
                            id={task._id}
                            data={task.analysis.image_label}
                            token={token}
                          ></ImageLabels>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_emotion"
                        >
                          <ImagePerson
                            id={task._id}
                            data={task.analysis.image_emotion}
                            token={token}
                          ></ImagePerson>
                        </CheckBlockPresence>

                        {!task.analysis.image_copywriting?.results.text_presence
                          ?.results?.textPresence ? (
                          <div></div>
                        ) : (
                          <CheckBlockPresence
                            analysis={task.analysis}
                            block="image_text_ratio"
                          >
                            <ImageTextRatio
                              id={task._id}
                              data={task.analysis.image_text_ratio}
                              token={token}
                              generateScore={this.getScoreTextRatio}
                            ></ImageTextRatio>
                          </CheckBlockPresence>
                        )}

                        {/* <CheckBlockPresence
                            analysis={task.analysis}
                            block="image_color"
                          >
                            <ImageColors
                              id={task._id}
                              data={task.analysis.image_color}
                            ></ImageColors>
                          </CheckBlockPresence> */}

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_emotion"
                        >
                          <ImageEmotions
                            id={task._id}
                            data={task.analysis.image_emotion}
                            token={token}
                            generateScore={this.getScoreEmotion}
                          ></ImageEmotions>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="dom_image"
                        >
                          <DomImage
                            id={task._id}
                            data={task.analysis.dom_image}
                            token={token}
                          ></DomImage>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_memorability"
                        >
                          <ImageMemorability
                            id={task._id}
                            data={task.analysis.image_memorability}
                            token={token}
                            generateScore={this.getScoreMemorability}
                          ></ImageMemorability>
                        </CheckBlockPresence>
                      </Col>
                    </Row>
          {/* Usability */}
                    <Row id="usability" className="mt-4">
                      <Col xs="12 fade-in">
                        <Row>
                          <div className="category-text">USABILITY</div>
                        </Row>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="dom_cta"
                        >
                          <DomCTA
                            id={task._id}
                            data={task.analysis.dom_cta}
                            token={token}
                            generateScore={this.getScoreDomCTA}
                          ></DomCTA>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="dom_form"
                        >
                          <DomForm
                            id={task._id}
                            data={task.analysis.dom_form}
                            token={token}
                          
                          ></DomForm>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_safety"
                        >
                          <ImageSafety
                            id={task._id}
                            data={task.analysis.image_safety}
                            token={token}
                            generateScore={this.getScoreSafety}
                          ></ImageSafety>
                        </CheckBlockPresence>

                        {!task.analysis.image_copywriting?.results.text_presence
                          ?.results?.textPresence ? (
                          <div></div>
                        ) : (
                          <CheckBlockPresence
                            analysis={task.analysis}
                            block="image_accessibility"
                          >
                            <ImageAccessibility
                              id={task._id}
                              data={task.analysis.image_accessibility}
                              token={token}
                              generateScore={this.getScoreAccessibility}
                            ></ImageAccessibility>
                          </CheckBlockPresence>
                        )}

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_colorblind"
                        >
                          <ImageColorblind
                            id={task._id}
                            data={task.analysis.image_colorblind}
                            token={token}
                          ></ImageColorblind>
                        </CheckBlockPresence>

                        {!task.analysis.image_copywriting?.results.text_presence
                          ?.results?.textPresence ? (
                          <div></div>
                        ) : (
                          <CheckBlockPresence
                            analysis={task.analysis}
                            block="image_accessibility"
                          >
                            <ImageColorblindAccess
                              id={task._id}
                              data={task.analysis.image_accessibility}
                              token={token}
                            ></ImageColorblindAccess>
                          </CheckBlockPresence>
                        )}
                      </Col>
                    </Row>

                    {/* Message */}
                    <Row id="message" className="mt-4">
                      <Col xs="12 fade-in">
                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="image_copywriting"
                        >
                          <ImageCopywriting
                            id={task._id}
                            data={task.analysis.image_copywriting}
                            token={token}
                            generateScore={this.getScoreCopywriting}
                          ></ImageCopywriting>
                        </CheckBlockPresence>

                        <CheckBlockPresence
                          analysis={task.analysis}
                          block="dom_text"
                        >
                          <DomText
                            id={task._id}
                            data={task.analysis.dom_text}
                            token={token}
                            generateScore={this.getScoreDomText}
                          ></DomText>
                        </CheckBlockPresence>
                      </Col>
                    </Row>

                    <CheckBlockPresence
                      analysis={task.analysis}
                      block="dom_rendering"
                    >
                      <DomRendering
                        id={task._id}
                        data={task.analysis.dom_rendering}
                        token={token}
                      ></DomRendering>
                    </CheckBlockPresence>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const Label = (props) => {
  return props.result == true ? (
    <Col xs="12 fade-in" className="small-score-label success-label">
      {props.label}
    </Col>
  ) : props.result == false ? (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  ) : props.result == "Maybe" ? (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  ) : (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  );
};



// If the results are available, display them
const CheckBlockPresence = (props) => {
  if (!props.analysis ) {
    return <span></span>;
  } else {
    return !props.analysis[props.block] ? (
      props.default ? (
        props.default
      ) : (
        <span></span>
      )
    ) : (props.analysis[props.block].status !== "finished" ? (
      props.default ? (
        props.default
      ) : (
        <span></span>
      )
    ) : (
      props.children
    ))
  }
};

const Cube = (props) => {
  return props.color == true ? (
    <img src={"/img/IconPassBig.png"} className="icon-score"></img>
  ) : props.color == "maybe" ? (
    <img src={"/img/IconWarningBig.png"} className="icon-score"></img>
  ) : (
    <img src={"/img/IconFailBig.png"} className="icon-score"></img>
  );
};

export { ReportTab, CheckBlockPresence, Cube };
