import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { CheckBlockPresence } from "./report";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

class Score extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var task = this.props.task;
    var state = this.props.state;

    this.state = {
      series: [99],
      optionsFormat: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#EA5455",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["FORMAT"],
      },
      optionsComposition: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#EA5455",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["COMPOSITION"],
      },
      optionsUsability: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#EA5455",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["USABILITY"],
      },
      optionsMessage: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
              background: "#f0f0f0",
            },
            track: {
              show: true,
              background: "#f2f2f2",
            },
            dataLabels: {
              name: {
                fontSize: "16px",
                color: "#63799b",
                fontFamily: "Graphik LC Web",
                offsetY: 80,
                fontWeight: "bold",
              },
              value: {
                offsetY: -5,
                fontSize: "30px",
                color: "#63799b",
                fontWeight: "bold",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            shadeIntensity: 0.75,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            type: "diagonal1",
            stops: [0, 50, 65, 91],
            colorStops: [
              {
                offset: 0,
                color: "#9162CE",
                opacity: 1,
              },
              {
                offset: 50,
                color: "#4d5af3",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#EA5455",
                opacity: 1,
              },
            ],
          },
        },
        stroke: {
          dashArray: 6,
        },
        labels: ["MESSAGE"],
      },
    };

    var scoreMessage = 0;

    if (task.analysis) {
      if (task.analysis.dom_text?.results) {
        console.log("dom text", state.scoreDomText);
        scoreMessage = state.scoreDomText;
      } else {
        if (
          task.analysis.image_copywriting?.results.text_presence?.results
            ?.textPresence == true
        ) {
          console.log("hello");
          scoreMessage = state.scoreCopywriting;
        } else {
          console.log("hello 2");
          scoreMessage = 0;
        }
      }
    }

    var scoreUsability;

    if (task.analysis) {
      if (task.analysis.dom_cta) {
        scoreUsability = state.scoreDomCTA;
      } else {
        scoreUsability = state.scoreAccessibility;
      }
    }

    var scoreComposition;

    if (task.analysis) {
      if (task.analysis.dom_navigation) {
        scoreComposition = state.scoreDomNavigation;
      } else {
        if (
          task.analysis.image_copywriting?.results.text_presence?.results
            ?.textPresence
        ) {
          if (task.analysis.image_emotion?.results.number_humans.results > 0) {
            console.log(state.scoreTextRatio);
            console.log(state.scoreSafety);
            scoreComposition =
              (1 / 11) * state.scoreTextRatio +
              (5 / 11) * state.scoreSafety +
              (5 / 11) * state.scoreEmotion;
          } else {
            console.log(state.scoreTextRatio);
            console.log(state.scoreSafety);
            scoreComposition =
              (1 / 6) * state.scoreTextRatio + (5 / 6) * state.scoreSafety;
          }
        } else {
          if (task.analysis.image_emotion?.results.number_humans.results > 0) {
            console.log(state.scoreTextRatio);
            console.log(state.scoreSafety);
            scoreComposition =
              (5 / 10) * state.scoreSafety + (5 / 10) * state.scoreEmotion;
          } else {
            console.log(state.scoreTextRatio);
            console.log(state.scoreSafety);
            scoreComposition = state.scoreSafety;
          }
        }
      }
    }

    return (
      <>
        <Row className="d-flex justify-content-around">
          {task.analysis.image_format ? (
            <Col xs="3 fade-in" className="text-center ">
              <a class="normal-a" href="#format">
                <Row className="justify-content-middle">
                  <ReactApexChart
                    options={this.state.optionsFormat}
                    series={[Math.round(state.scoreFormat)]}
                    type="radialBar"
                    height={250}
                    labels={["FORMAT"]}
                  />
                </Row>

                {/* <Row className="justify-content-middle">
                <Col className="score-label">Format</Col>
              </Row> */}
              </a>

              <CheckBlockPresence analysis={task.analysis} block="image_format">
                <Label
                  result={
                    task.analysis.image_format?.results.correct_aspect_ratio
                      .results.correctRatio
                  }
                  label="Ratio"
                ></Label>
                <Label
                  result={
                    task.analysis.image_format?.results.correct_image_resolution
                      .results.correctResolution
                  }
                  label="Image size"
                ></Label>
                {/* <Label
                result={
                  task.analysis.image_format?.results.correct_file_extension
                    .results.correctFormat
                }
                label="File type"
              ></Label> */}
                <Label
                  result={
                    task.analysis.image_format?.results.correct_colorspace
                      .results.correctColorspace
                  }
                  label="Colorspace"
                ></Label>
              </CheckBlockPresence>
            </Col>
          ) : (
            // <Row className="justify-content-middle full-height">
            // <div className="score-round align-middle">-</div>
            // </Row>
            <></>
          )}

          <Col xs="3 fade-in" className="text-center ">
            <a class="normal-a" href="#composition">
              <Row>
                <ReactApexChart
                  options={this.state.optionsComposition}
                  series={[Math.round(scoreComposition)]}
                  type="radialBar"
                  height={250}
                  label={["COMPOSITION"]}
                />
              </Row>
              {/* <Row className="justify-content-middle">
                <Col className="score-label">Composition</Col>
              </Row> */}
            </a>

            <CheckBlockPresence analysis={task.analysis} block="dom_navigation">
              {task.analysis?.dom_navigation?.results?.navigation_coord ? (
                <>
                  <Label
                    result={
                      task.analysis.dom_navigation.results.navigation_coord
                        .results.has_nav
                    }
                    label="Navigation Presence"
                  ></Label>

                  <Label
                    result={
                      task.analysis.dom_navigation.results.navigation_coord
                        ?.results?.correctly_placed
                    }
                    label="Navigation Correctly Placed"
                  ></Label>
                </>
              ) : (
                <></>
              )}
              {task.analysis?.dom_navigation?.results?.search_presence ? (
                <>
                  <Label
                    result={
                      task.analysis.dom_navigation.results.search_presence
                        ?.results?.has_search
                    }
                    label="Search Presence"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>

            <CheckBlockPresence analysis={task.analysis} block="image_safety">
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_adult
                }
                label="Safety: Adult content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_medical
                }
                label="Safety: Medical content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_racy
                }
                label="Safety: Racy content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_spoofed
                }
                label="Safety: Spoofed content"
              ></Label>
              <Label
                result={
                  task.analysis.image_safety?.results.correct_safety.results
                    .correct_violence
                }
                label="Safety: Violent content"
              ></Label>
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={task.analysis}
              block="image_copywriting"
            >
              {task.analysis.image_copywriting?.results.text_presence?.results
                ?.textPresence ? (
                <>
                  {" "}
                  <CheckBlockPresence
                    analysis={task.analysis}
                    block="image_text_ratio"
                  >
                    <Label
                      result={
                        task.analysis.image_text_ratio?.results.correct_ratio
                          .results.correct_ratio
                      }
                      label="Image:Text ratio"
                    ></Label>
                  </CheckBlockPresence>
                </>
              ) : (
                <> </>
              )}
            </CheckBlockPresence>
            {task.analysis.image_emotion?.results.number_humans.results > 0 ? (
              <CheckBlockPresence
                analysis={task.analysis}
                block="image_emotion"
              >
                <Label
                  result={
                    task.analysis.image_emotion?.results.correct_emotion.results
                      .correct_anger
                  }
                  label="Level of anger"
                ></Label>
                <Label
                  result={
                    task.analysis.image_emotion?.results.correct_emotion.results
                      .correct_joy
                  }
                  label="Level of joy"
                ></Label>
                <Label
                  result={
                    task.analysis.image_emotion?.results.correct_emotion.results
                      .correct_sorrow
                  }
                  label="Level of sorrow"
                ></Label>
                <Label
                  result={
                    task.analysis.image_emotion.results?.correct_emotion.results
                      .correct_surprise
                  }
                  label="Level of surprise"
                ></Label>
              </CheckBlockPresence>
            ) : (
              <></>
            )}
          </Col>
          <Col xs="3 fade-in" className="text-center ">
            <a class="normal-a" href="#usability">
              <Row>
                <ReactApexChart
                  options={this.state.optionsUsability}
                  series={[Math.round(scoreUsability)]}
                  type="radialBar"
                  height={250}
                />
              </Row>
              {/* <Row className="justify-content-middle">
                <Col className="score-label">Usability</Col>
              </Row> */}
            </a>

            {/* <CheckBlockPresence
                            analysis={task.analysis}
                            block="image_color"
                          >
                            <Label result={true} label="Colors"></Label>
                          </CheckBlockPresence> */}

            <CheckBlockPresence analysis={task.analysis} block="dom_cta">
              {task.analysis?.dom_cta?.results?.CTA_presence?.results
                ?.cta_presence ? (
                <>
                  {task.analysis.dom_cta.results.CTA_bold.results ? (
                    <Label
                      result={
                        task.analysis.dom_cta.results?.CTA_bold?.results
                          ?.correct_cta_bold
                      }
                      label="CTA Boldness"
                    ></Label>
                  ) : (
                    <></>
                  )}
                  <Label
                    result={
                      task.analysis.dom_cta.results?.CTA_textual?.results
                        .correct_cta_char
                    }
                    label="Textual CTA"
                  ></Label>
                  <Label
                    result={
                      task.analysis.dom_cta.results?.CTA_duplicate?.results?.correct_cta_duplicate
                    }
                    label="CTA Duplicates"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={task.analysis}
              block="image_copywriting"
            >
              {task.analysis?.image_copywriting?.results.text_presence?.results
                ?.textPresence ? (
                <>
                  <CheckBlockPresence
                    analysis={task.analysis}
                    block="image_accessibility"
                  >
                    <Label
                      result={
                        task.analysis.image_accessibility?.results
                          ?.correct_average_score.results.correct_accessibility
                          .correct_accessibility_aaa
                      }
                      label="AAA Accessibility level"
                    ></Label>
                    <Label
                      result={
                        task.analysis.image_accessibility?.results
                          ?.correct_average_score.results.correct_accessibility
                          .correct_accessibility_aa
                      }
                      label="AA Accessibility level"
                    ></Label>
                    <Label
                      result={
                        task.analysis.image_accessibility?.results
                          ?.correct_average_score.results.correct_accessibility
                          .correct_accessibility_large
                      }
                      label="Large Accessibility level"
                    ></Label>
                  </CheckBlockPresence>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
          </Col>

          <Col xs="3 fade-in" className="text-center ">
            <a class="normal-a" href="#message">
              <Row>
                <ReactApexChart
                  options={this.state.optionsMessage}
                  series={[Math.round(scoreMessage)]}
                  type="radialBar"
                  height={250}
                />
              </Row>
              {/* <Row className="justify-content-middle">
                <Col className="score-label">message</Col>
              </Row> */}
            </a>
            <CheckBlockPresence analysis={task.analysis} block="dom_text">
              {task.analysis?.dom_text?.result ? (
                <>
                  {" "}
                  <Label
                    result={
                      task.analysis?.dom_text?.results?.text_image_ratio
                        ?.results.correct_ratio
                    }
                    label="Text:Image ratio"
                  ></Label>
                  <Label
                    result={
                      task.analysis?.dom_text?.results?.wordiness?.results
                        .correct_text_char
                    }
                    label="Wordiness"
                  ></Label>
                  <Label
                    result={
                      task.analysis?.dom_text?.results?.font_number?.results
                        .correct_font_number
                    }
                    label="Font Number"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={task.analysis}
              block="image_copywriting"
            >
              {task.analysis.image_copywriting?.results.text_presence?.results
                ?.textPresence ? (
                <>
                  <Label
                    result={
                      task.analysis.image_copywriting?.results.text_too_short
                        .results.averageTooShort *
                        100 ==
                      0
                        ? true
                        : task.analysis.image_copywriting?.results
                            .text_too_short.results.averageTooShort *
                            100 <
                          50
                        ? "Maybe"
                        : false
                    }
                    label="Wordiness"
                  ></Label>
                  <Label
                    result={
                      task.analysis.image_copywriting?.results.text_too_long
                        .results.averageTooLong *
                        100 ==
                      0
                        ? true
                        : task.analysis.image_copywriting?.results.text_too_long
                            .results.averageTooLong *
                            100 <
                          50
                        ? "Maybe"
                        : false
                    }
                    label="Conciseness"
                  ></Label>
                </>
              ) : (
                <></>
              )}
            </CheckBlockPresence>
          </Col>
        </Row>
        {/* <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={250}
        /> */}
      </>
    );
  }
}

const Label = (props) => {
  return props.result == true ? (
    <Col xs="12 fade-in" className="small-score-label success-label">
      {props.label}
    </Col>
  ) : props.result == false ? (
    <Col xs="12 fade-in" className="small-score-label fail-label">
      {props.label}
    </Col>
  ) : props.result == "Maybe" ? (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  ) : (
    <Col xs="12 fade-in" className="small-score-label warning-label">
      {props.label}
    </Col>
  );
};

export default Score;
