import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import {Cube} from "../tabs/report"
class ImagePerson extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row className="border-row mt-4 no-top-border">
        <Col className="py-2 text-title" xs="4">
          Number of person detected
        </Col>
        <Col xs="6" className="py-2">
          {this.props.data.results.number_humans.results}
        </Col>
      </Row>
    );
  }
}



export default ImagePerson;
