/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Tooltip,
} from "reactstrap";
import striptags from "striptags";
import industries from "../../variables/industries.json";
import geographies from "../../variables/geographies.json";
import channels from "../../variables/channels.json";
import types from "../../variables/types.json";
import axios from "axios";
import ErrorJSON from "../utils/Error";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
      projects: [],
      tasks: [],
      oldTasks: [],
      team: { public: false },
    };
  }

  toggle(_task, team_id) {
    if (_task._id) {
      var task = JSON.parse(JSON.stringify(_task));
      this.setState({
        modal: !this.state.modal,
        ...task,
        oldTasks: task.reports !== undefined ? task.reports.slice(0) : [],
      });
      axios
        .get("/platform/projects/getlistperteam", { params: { id: team_id } })
        .then((res) => {
          this.setState({
            projects: res.data.projects,
            team: res.data.team,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  }

  handleCheck(value) {
    this.setState((prevState) => {
      const tasks = prevState.tasks;

      console.log("tasks1", tasks);

      if (tasks.indexOf(value) !== -1) {
        tasks.splice(tasks.indexOf(value), 1);
      } else {
        tasks.push(value);
        if (value == "experience") {
          if (!prevState.tasks.includes("memorability")) {
            tasks.push("memorability");
          }
          if (!prevState.tasks.includes("mcu")) {
            tasks.push("mcu");
          }
        }
        if (value == "mcu") {
          if (!prevState.tasks.includes("memorability")) {
            tasks.push("memorability");
          }
        }
      }

      console.log("tasks", tasks);
      return { tasks: tasks };
    });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    if (nam === "tasks") {
      val = JSON.parse(val);
      this.setState({ [nam]: val });
    } else {
      this.setState({ [nam]: striptags(val) });
    }
  }

  componentWillUnmount() {
    this.setState({
      modal: false,
      projects: [],
      tasks: [],
      oldTasks: [],
      team: { public: false },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.tasks);

    if (
      this.state.tasks.indexOf("mcu") !== -1 &&
      (this.state.industry === "" || this.state.type === "")
    ) {
      this.setState({
        submitted: false,
        errors:
          "You must indicate a type and an industry to perform the Creativity Report.",
      });
      return;
    }

    axios
      .post("/platform/tasks/edit", {
        ...this.state,
        tasks: this.state.tasks,
        id: this.state._id,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit task</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <FormGroup>
                <Label for="name">Task label</Label>
                <Input
                  type="text"
                  name="label"
                  id="name"
                  onChange={this.handleChange}
                  required
                  maxLength={30}
                  placeholder="Enter a label"
                  value={this.state.label}
                />
              </FormGroup>
              {console.log("this.team", this.state.team)}
              {this.state.team.public ? (
                <span></span>
              ) : (
                <FormGroup>
                  <Label for="name">Project</Label>
                  <Input
                    onChange={this.handleChange}
                    required
                    type="select"
                    name="project"
                  >
                    <option disabled selected value="">
                      Select the project of the task
                    </option>
                    {this.state.projects.map((project) => {
                      return (
                        <option
                          selected={
                            this.state.project?._id === project._id
                              ? true
                              : false
                          }
                          value={project._id}
                        >
                          {project.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              )}

              <FormGroup className="form-create-check" check>
                <Tooltiped
                  id="visualattention"
                  text="This feature is only available for upgraded accounts."
                  disabled={this.props.access.indexOf("vco") === -1}
                >
                  <Label className="mr-5 pl-0" check>
                    <FontAwesomeIcon className="mr-3" icon="check" />

                    <span
                      className={
                        this.props.access.indexOf("vco") === -1
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Visual Attention Report{" "}
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="memorability"
                  text="This feature is only available for upgraded accounts."
                  disabled={this.props.access.indexOf("memorability") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("memorability") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("memorability") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("memorability")}
                          type="checkbox"
                          checked={
                            this.state.tasks.indexOf("memorability") !== -1 ||
                            this.state.tasks.indexOf("experience") !== -1 ||
                            this.state.tasks.indexOf("mcu") !== -1
                          }
                          disabled={
                            this.state.tasks.indexOf("mcu") !== -1 ||
                            this.state.tasks.indexOf("experience") !== -1 ||
                            this.props.access.indexOf("memorability") === -1
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.state.tasks.indexOf("mcu") !== -1 ||
                            this.state.tasks.indexOf("experience") !== -1 ||
                            this.props.access.indexOf("memorability") === -1
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props.access.indexOf("memorability") === -1
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Memorability Report
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="mcu"
                  text={
                    this.props.access.indexOf("mcu") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={this.props.access.indexOf("mcu") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("mcu") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("mcu") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("mcu")}
                          type="checkbox"
                          checked={
                            this.state.tasks.indexOf("experience") !== -1 ||
                            this.state.tasks.indexOf("mcu") !== -1
                          }
                          disabled={
                            this.state.tasks.indexOf("experience") !== -1 ||
                            this.props.access.indexOf("mcu") === -1
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.state.tasks.indexOf("experience") !== -1 ||
                            this.props.access.indexOf("mcu") === -1
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props.access.indexOf("mcu") === -1
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Predictive design
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="experience"
                  text={
                    this.props.access.indexOf("experience") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={this.props.access.indexOf("experience") === -1}
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("experience") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("experience") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("experience")}
                          type="checkbox"
                          disabled={
                            this.props.access.indexOf("experience") === -1 ||
                            this.state.media !== "dom"
                          }
                        />
                        <span
                          className={
                            "form-check-sign " +
                            (this.props.access.indexOf("experience") === -1 ||
                            this.state.media !== "dom"
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}
                    <span
                      className={
                        this.props.access.indexOf("experience") === -1 ||
                        this.state.media !== "dom"
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Page Experience Report
                    </span>
                  </Label>
                </Tooltiped>

                <Tooltiped
                  id="accessibility"
                  text={
                    this.props.access.indexOf("accessibility") === -1
                      ? "This feature is only available for upgraded accounts."
                      : "This feature is not available for this type of content."
                  }
                  disabled={
                    this.props.access.indexOf("accessibility") === -1 ||
                    !(this.state.media === "dom" && this.state.type === "email")
                  }
                >
                  <Label
                    className={
                      "mr-5 mt-3 " +
                      (this.state.oldTasks.indexOf("accessibility") !== -1
                        ? "pl-0"
                        : "")
                    }
                    check
                  >
                    {this.state.oldTasks.indexOf("accessibility") === -1 ? (
                      <span>
                        <Input
                          onChange={() => this.handleCheck("accessibility")}
                          type="checkbox"
                          disabled={
                            this.props.access.indexOf("accessibility") === -1 ||
                            !(
                              this.state.media === "dom" &&
                              this.state.type === "email"
                            )
                          }
                        />{" "}
                        <span
                          className={
                            "form-check-sign " +
                            (this.props.access.indexOf("accessibility") ===
                              -1 ||
                            !(
                              this.state.media === "dom" &&
                              this.state.type === "email"
                            )
                              ? "disabled"
                              : "")
                          }
                        >
                          <span className="check"></span>
                        </span>
                      </span>
                    ) : (
                      <FontAwesomeIcon className="mr-3" icon="check" />
                    )}

                    <span
                      className={
                        this.props.access.indexOf("accessibility") === -1 ||
                        !(
                          this.state.media === "dom" &&
                          this.state.type === "email"
                        )
                          ? "disabled-txt"
                          : ""
                      }
                    >
                      Accessibility Report
                    </span>
                  </Label>
                </Tooltiped>
              </FormGroup>

              <FormGroup>
                <Label for="name">Content type</Label>
                <Input onChange={this.handleChange} type="select" name="type">
                  <option disabled selected value="">
                    Select the type of content
                  </option>
                  {types.map((type) => {
                    if (type.available_for.indexOf(this.state.media) !== -1) {
                      return (
                        <option
                          selected={
                            this.state.type === type.name ? true : false
                          }
                          key={type.name}
                          value={type.name}
                        >
                          {type.pretty_name}
                        </option>
                      );
                    }
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="name">Industry</Label>
                <Input
                  onChange={this.handleChange}
                  type="select"
                  name="industry"
                >
                  <option disabled selected value="">
                    Select the industry
                  </option>
                  {industries.map((industry) => {
                    return (
                      <option
                        selected={
                          this.state.industry === industry.name ? true : false
                        }
                        key={industry.name}
                        value={industry.name}
                      >
                        {industry.pretty_name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="name">Geography</Label>
                <Input
                  onChange={this.handleChange}
                  type="select"
                  name="geography"
                >
                  <option disabled selected value="">
                    Select the geography
                  </option>
                  {geographies.map((geography) => {
                    return (
                      <option
                        selected={
                          this.state.geography === geography.name ? true : false
                        }
                        key={geography.name}
                        value={geography.name}
                      >
                        {geography.pretty_name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              {this.state.type == "banner" ? (
                <FormGroup>
                  <Label for="name">Channel</Label>
                  <Input
                    onChange={this.handleChange}
                    type="select"
                    name="channel"
                  >
                    <option disabled selected value="">
                      Select the channel
                    </option>
                    {channels.map((channel) => {
                      return (
                        <option
                          selected={
                            this.state.channel === channel.name ? true : false
                          }
                          key={channel.name}
                          value={channel.name}
                        >
                          {channel.pretty_name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              ) : (
                <span></span>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Edit;

const Tooltiped = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={props.id}>{props.children}</span>
      {props.disabled == true ? (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={props.id}
          toggle={toggle}
        >
          {props.text}
        </Tooltip>
      ) : (
        <span></span>
      )}
    </span>
  );
};
