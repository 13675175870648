import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
import ImageSafety from "./ImageSafety";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

var safetys = [
  { name: "medical", pretty: "Medical" },
  { name: "spoofed", pretty: "Spoofed" },
  { name: "adult", pretty: "Adult" },
  { name: "racy", pretty: "Racy" },
  { name: "violence", pretty: "Violence" },
];
class DomImage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const ref = React.createRef(null);
    const scroll = (scrollOffset) => {
      ref.current.scrollLeft += scrollOffset;
    };

    return (
      <>
        <InfoModal
          ref={(modal) => {
            this.infoModalRef = modal;
          }}
        ></InfoModal>
        {this.props.data?.results ? (
          <>
            {this.props.data.results.img_data.results.length == 0 ? (
              <>
                <Row className=" mt-4 no-top-border">
                  <Col className="py-2 text-title" xs="4">
                    Image
                  </Col>
                  <Col className="py-2 text-title dark-background" xs="3">
                    RESULT
                  </Col>
                  <Col className="py-2 text-title dark-background" xs="3">
                    RECOMMENDED
                  </Col>
                  <Col className="py-2 text-title" xs="2">
                    DETAILS
                  </Col>
                </Row>
                <Row className="border-row">
                  <Col xs="4" className="py-3">
                    <Row>
                      <Col xs="2">
                        <Cube color={false}></Cube>
                      </Col>
                      <Col xs="10">Images</Col>
                    </Row>
                  </Col>
                  <Col xs="3" className="py-3 dark-background">
                    No images have been found in the document.
                  </Col>
                  <Col xs="3" className="py-3 dark-background"></Col>
                  <Col xs="2" className="py-3">
                    {this.props.data.results?.reco?.results?.reco_neg}
                  </Col>
                </Row>
              </>
            ) : (
              <Row className="border-row mt-4 no-top-border">
                <Col className="py-2 text-title" xs="12">
                  Images analysis
                </Col>
                <div className="container-scroll" ref={ref}>
                  <div class="chevron-height">
                  <div class="chevron-left" onClick={() => scroll(-50)}><i class="fas fa-chevron-left"></i></div>
                  </div>
                  <Col xs="1">
                    </Col>
         
                  {this.props.data.results.img_data.results.map(
                    (img, index) => {
                      console.log("PROPS");
                      return (
                        <Col xs="3">
                          <Card>
                            <LazyLoadImage
                              placeholderSrc={
                                "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                              }
                              effect="blur"
                              src={`/platform/attachments/serveattachment?name=domimage_${index}&id=${this.props.id}&token=${this.props.token}`}
                            />

                            <CardBody>
                              Positive sentiment
                              <FontAwesomeIcon
                             onClick={() => this.infoModalRef.toggle()}
                                className="info-icon"
                                icon="info-circle"
                              ></FontAwesomeIcon>
                              <Progress
                                className="w-100 position-relative progress-light"
                                value={img.sentiment.positive}
                                color={
                                  img.sentiment.positive > 30
                                    ? "success"
                                    : "danger"
                                }
                              >
                                <span className="progress-text">
                                  {" "}
                                  {Math.round(img.sentiment.positive)}%
                                </span>
                              </Progress>
                              <hr></hr>
                              {img.faces.length > 0 ? (
                                <span>
                                  {img.faces.length} human
                                  {img.faces.length > 1 ? "s" : ""} detected.
                                </span>
                              ) : (
                                <span>No humans detected.</span>
                              )}
                              <hr></hr>
                              {safetys.map((safety) => {
                                if (
                                  this.props.data.results.safety_scores.results
                                ) {
                                  var res = this.props.data.results
                                    .safety_scores.results[index];

                                  return (
                                    <>
                                      Safety scores
                                      <br></br>
                                      <span>{safety.pretty}</span>
                                      <Row>
                                        <Col xs={12}>
                                          <Progress
                                            className="w-100 text-center progress-light"
                                            value={
                                              res[
                                                "safety_number_" + safety.name
                                              ]
                                            }
                                            color={
                                              res[
                                                "safety_number_" + safety.name
                                              ] < res["expected" + safety.name]
                                                ? "success"
                                                : "danger"
                                            }
                                          >
                                            <span className="progress-text">
                                              {
                                                res[
                                                  "safety_number_" + safety.name
                                                ]
                                              }
                                              %
                                            </span>
                                          </Progress>
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    }
                  )}
                    <Col xs="1">
                    </Col>
                    <div class="chevron-height-right">
                   <div class="chevron-right" onClick={() => scroll(50)}><i class="fas fa-chevron-right"></i></div>
                   </div>
                </div>
              </Row>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);


    this.state = {
      modal: false,
    };
  }

  toggle(project) {
    this.setState({ modal: !this.state.modal});
  }


  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Image sentiment analysis</ModalHeader>

          <ModalBody>

    Image Sentiment Analysis is a neural network-based image classification that is tailored to feelings expressed in images from various domains. It notes the feelings giving a degree of positivity and a degree of negativity from a given image. <br></br><br></br>
The sentiment analysis was formed on 600 images and validated on 200 images reaching high precision. The dataset consists of images that were presented to at least 10 different people to determine if the image expresses positive or negative sentiment.

        
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Close
            </Button>
   
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default DomImage;
