import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
class ImageAccessibility extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    if (this.props.data.results) {
      if (
        this.props.data.results.correct_average_score.results
          .correct_accessibility.correct_accessibility_aa
      ) {
        score = score + 1;
      }
      if (
        this.props.data.results.correct_average_score.results
          .correct_accessibility.correct_accessibility_aaa
      ) {
        score = score + 1;
      }
      if (
        this.props.data.results.correct_average_score.results
          .correct_accessibility.correct_accessibility_large
      ) {
        score = score + 1;
      }
    }

    let scorefinal = (score / 3) * 100;

    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        {this.props.data.results ? (
          <>
            <Row className="mt-2">
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_average_score.results
                          .correct_accessibility.correct_accessibility_aaa
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">AAA Accessibility level</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                Text passing AAA accessibility{" "}
                {Math.round(
                  this.props.data.results.correct_average_score.results.score
                    .averageScoreAAA
                )}{" "}
                %
              </Col>
              <Col xs="3" className="py-3 dark-background">
                Expected{" "}
                {
                  this.props.data.results.correct_average_score.results
                    .min_average_aaa
                }
                %
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_average_score.results
                  .correct_accessibility.correct_accessibility_aaa ? (
                  <div>
                    {" "}
                    {
                      this.props.data.results.correct_average_score.results
                        .reco_pos_min_average_aaa
                    }
                  </div>
                ) : (
                  <div>
                    {" "}
                    {
                      this.props.data.results.correct_average_score.results
                        .reco_neg_min_average_aaa
                    }
                  </div>
                )}
              </Col>
            </Row>
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_average_score.results
                          .correct_accessibility.correct_accessibility_aa
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">AA Accessibility level</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                Text passing AA accessibility{" "}
                {Math.round(
                  this.props.data.results.correct_average_score.results.score
                    .averageScoreAA
                )}{" "}
                %
              </Col>
              <Col xs="3" className="py-3 dark-background">
                Expected{" "}
                {
                  this.props.data.results.correct_average_score.results
                    .min_average_aa
                }
                %
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_average_score.results
                  .correct_accessibility.correct_accessibility_aa ? (
                  <div>
                    {" "}
                    {
                      this.props.data.results.correct_average_score.results
                        .reco_pos_min_average_aa
                    }
                  </div>
                ) : (
                  <div>
                    {" "}
                    {
                      this.props.data.results.correct_average_score.results
                        .reco_neg_min_average_aa
                    }
                  </div>
                )}
              </Col>
            </Row>
            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_average_score.results
                          .correct_accessibility.correct_accessibility_large
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Large Accessibility level</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                Text passing Large accessibility{" "}
                {Math.round(
                  this.props.data.results.correct_average_score.results.score
                    .averageScoreLarge
                )}{" "}
                %
              </Col>
              <Col xs="3" className="py-3 dark-background">
                Expected{" "}
                {
                  this.props.data.results.correct_average_score.results
                    .min_average_large
                }
                %
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_average_score.results
                  .correct_accessibility.correct_accessibility_large ? (
                  <div>
                    {" "}
                    {
                      this.props.data.results.correct_average_score.results
                        .reco_pos_min_average_large
                    }
                  </div>
                ) : (
                  <div>
                    {" "}
                    {
                      this.props.data.results.correct_average_score.results
                        .reco_neg_min_average_large
                    }
                  </div>
                )}
              </Col>
            </Row>{" "}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default ImageAccessibility;
