import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
class ImageFormats extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let scorefinal =
      // ((this.props.data.results.correct_colorspace.results.score +
      //   this.props.data.results.correct_file_extension.results.score +
      //   this.props.data.results.correct_image_resolution.results.score +
      //   this.props.data.results.correct_aspect_ratio.results.score) /
      //   4) *
      // 100;

      ((this.props.data.results.correct_colorspace.results.score +
        this.props.data.results.correct_image_resolution.results.score +
        this.props.data.results.correct_aspect_ratio.results.score) /
        3) *
      100;

    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        <Row id="format" className="mt-4">
          <Col xs="12 fade-in">
            <Row>
              <div className="category-text">FORMAT</div>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_aspect_ratio.results
                          .correctRatio
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Ratio</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {
                  this.props.data.results.correct_aspect_ratio.results
                    .closest_ratio
                }
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {JSON.parse(
                  this.props.data.results.correct_aspect_ratio.results.expected_ratio.replace(
                    /'/g,
                    '"'
                  )
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                })}
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_aspect_ratio.results
                  .correctRatio ? (
                  <div>
                    {
                      this.props.data.results.correct_aspect_ratio.results
                        .reco_pos_ratio
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_aspect_ratio.results
                        .reco_neg_ratio
                    }
                  </div>
                )}
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_image_resolution.results
                          .correctResolution
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Image size</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {this.props.data.results.correct_image_resolution.results.width}
                x
                {
                  this.props.data.results.correct_image_resolution.results
                    .height
                }
                px
              </Col>
              <Col xs="3" className="py-3 dark-background">
                <Row>
                  <Col>
                    Min Width:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .min_width
                    }
                    px
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Min Height:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .min_height
                    }
                    px
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Max Width:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .max_width
                    }
                    px
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Max Height:{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .max_height
                    }
                    px
                  </Col>
                </Row>
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_image_resolution.results
                  .correctResolution ? (
                  <div>
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_pos_min_height
                    }{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_pos_max_height
                    }{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_pos_min_width
                    }{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_pos_max_width
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_neg_min_height
                    }{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_neg_max_height
                    }{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_neg_min_width
                    }{" "}
                    {
                      this.props.data.results.correct_image_resolution.results
                        .reco_neg_max_width
                    }
                  </div>
                )}
              </Col>
            </Row>

            {/* <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_file_extension.results
                          .correctFormat
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">File type</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {this.props.data.results.correct_file_extension.results.format}
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {JSON.parse(
                  this.props.data.results.correct_file_extension.results.expected_format.replace(
                    /'/g,
                    '"'
                  )
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                })}
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_file_extension.results
                  .correctFormat ? (
                  <div>
                    {
                      this.props.data.results.correct_file_extension.results
                        .reco_pos_format
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_file_extension.results
                        .reco_neg_format
                    }
                  </div>
                )}
              </Col>
            </Row> */}

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_colorspace.results
                          .correctColorspace
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Colorspace</Col>
                </Row>
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {this.props.data.results.correct_colorspace.results.colorspace}
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {JSON.parse(
                  this.props.data.results.correct_colorspace.results.expected_colorspace.replace(
                    /'/g,
                    '"'
                  )
                ).map(function (ratio) {
                  return (
                    <Row>
                      <Col>{ratio}</Col>
                    </Row>
                  );
                })}
              </Col>
              <Col xs="2" className="py-3">
                {this.props.data.results.correct_colorspace.results
                  .correctColorspace ? (
                  <div>
                    {
                      this.props.data.results.correct_colorspace.results
                        .reco_pos_colorspace
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_colorspace.results
                        .reco_neg_colorspace
                    }
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageFormats;
