import React, { Component } from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import moment from "moment";
import { setPageTitle } from "../../actions/titleActions";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pending_users: []
    }

  this.columns = [
    {
      name: "Name",
      selector: (row) => row.full_name,
      cell: (row) => <b>{row.full_name}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Job title",
      selector: "job_title",
      sortable: true,
      center: true,
    },
    {
      name: "Creation",
      selector: (row) => moment(row.creation).unix(),
      cell: (row) => moment(row.creation).format("lll"),
      sortable: true,
      center: true,
    }
  ];
}


  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setPageTitle("Dashboard", "tachometer-alt"));
    axios
    .get("/platform/admin/users/getpending")
    .then((res) => {
      this.setState({ pending_users: res.data});
    })
    .catch((err) => {
      console.log(err);
          this.props.history.push("/home");
    });
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>CRISP - Dashboard</title>
        </Helmet>{" "}
        <Card>
          <CardBody>
            <DataTable
              title="Pending users"
              pagination={true}
              columns={this.columns}
              data={this.state.pending_users}
              subHeader={true}
              striped={true}
              className="clickable-rows"
              onRowClicked={(row) =>
                this.props.history.push("/admin/users/" + row._id)
              }
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect()(Dashboard);
