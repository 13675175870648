/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";

import axios from "axios";
import ErrorJSON from "../../utils/Error";

class Delete extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    axios
      .post("/platform/admin/teams/delete", { id: this.props.team })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Delete team</ModalHeader>

          <ModalBody>
            {this.state.errors ? (
              <ErrorJSON json={this.state.errors}></ErrorJSON>
            ) : (
              <span></span>
            )}
            Do you want to delete this team and all its related projects?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="danger" onClick={this.handleSubmit} type="submit">
              Delete
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Delete;
