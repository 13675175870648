import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import industries from "../../../variables/industries.json";
import geographies from "../../../variables/geographies.json";
import channels from "../../../variables/channels.json";
import types from "../../../variables/types.json";
import jsonReport from '../../../out.json';
import ReportViewer from '../../../lighthouse-viewer/src';
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class LighthouseTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        jsonReport: false
    }
    this.token = this.props.token;
  }

  componentDidUpdate() {
    if(this.token !== this.props.token) {
      this.token = this.props.token;
      this.fetchData();
    }
  }

  componentDidMount() {

      this.fetchData();
    
  }
  
  fetchData() {

      axios.get(`/platform/attachments/serveattachment?name=lighthouse&id=${this.props.task._id}&token=${this.token}`)
      .then(res => {
        this.setState({jsonReport:res.data})
      }).catch (err => {

      })

  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    return (
        <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <div className="mt-4 px-4">
              
                <Row>
          
                <Col>
                {this.state.jsonReport !== false ? (  <ReportViewer json={this.state.jsonReport}></ReportViewer>) : (<span></span>)}

                </Col>
                </Row>
              
            </div>
          )}
        </Col>
      </Row>
    );
  }
}



export default LighthouseTab;
