import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class ImageMemorability extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    console.log("memo", this.props.data);
    if (this.props.data.results.score) {
      score = Math.round(this.props.data.results.score * 100);
    } else if (this.props.data.results.result.results.score) {
      score = Math.round(this.props.data.results.result.results.score * 100);
    }

    this.props.generateScore(score);
  }

  render() {
    var score = 0

    if (this.props.data.results.score) {
      score = this.props.data.results.score * 50 + 50;
    } else if (this.props.data.results.result.results.score) {
      score = this.props.data.results.result.results.score * 50 + 50;
    }

    return (
      <>
        <Row className="border-row no-top-border mt-3">
          <Col className="py-2 text-title" xs="4">
            HUMAN MEMORABILITY PREDICTION
          </Col>
          <Col className="py-2 text-title dark-background" xs="6">
            SCORE
          </Col>
          {/* <Col className="py-2 text-title dark-background" xs="3">
            Label
          </Col> */}
          <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col>
        </Row>
        <Row>
          <Col xs="4" className="py-3">
            <div>
              <LazyLoadImage
                         placeholderSrc={"data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"}
                         effect="blur"
                         src={`/platform/attachments/serveattachment?name=memorability&id=${this.props.id}&token=${this.props.token}`} />
             
            </div>
          </Col>
          <Col xs="6" className="py-3 dark-background">
            <Progress
              className="w-100 white-bg"
              color={
                score < 25
                  ? "danger"
                  : score < 50
                  ? "warning"
                  : score < 75
                  ? "yellow"
                  : "success"
              }
              value={score}
            />
            <div className="w-100 mt-2 score-memo">{Math.round(score)}/100</div>
          </Col>
          <Col xs="2" className="py-3">
          {score > 75 ? (
                  <div>{this.props.data.results?.recommendation?.results
                    ?.reco_pos}</div>
                ) : (
                  <div>{this.props.data.results?.recommendation?.results
                    ?.reco_neg}</div>
                )}
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageMemorability;
