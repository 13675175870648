import React from "react";

import Create from "../project/Create";
import Edit from "../project/Edit";
import Delete from "../project/Delete";
import axios from "axios";
import {
  Row,
  Button,
} from "reactstrap";

import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import ProjetCard from "../project/ProjectCard";

import ContentLoader from "react-content-loader";
class All extends React.Component {
  constructor(props) {
    super(props);
    //this.onResolved = this.onResolved.bind(this);

    this.state = {
      projects: false,
      offset: 1,
    };
    this.team = this.props.match.params.id;
  }

  fetchData() {
    var team = this.team;
    const { dispatch } = this.props;
    window.addEventListener("scroll", this.handleScroll.bind(this)); 
    axios
      .get("/platform/projects/getperteam", {
        params: {
          id: team,
        },
      })
      .then((res) => {
        dispatch(setPageTitle(res.data.team.name, "users"));
        this.setState({
          projects: res.data.projects,
          team_name: res.data.team.name,
        });
      })
      .catch((err) => {
            this.props.history.push("/home");
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    if(this.team !== this.props.match.params.id) {
      this.team = this.props.match.params.id;
      this.fetchData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
 
handleScroll() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= 0.9*docHeight) {
      this.getMore();
    } 
  }


  getMore() {
    var team = this.team;

    if(this.state.projects != false){

    axios
      .get("/platform/projects/getperteam", {
        params: {
          id: team,
          offset: this.state.offset,
        },
      })
      .then((res) => {
        this.setState((prevState) => ({
          offset: prevState.offset + 1,
          projects: [...prevState.projects, ...res.data.projects],
        }));
      })
      .catch((err) => {});

    }
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{`CRISP - ${this.state.team_name}`}</title>
        </Helmet>
        <Create
          ref={(modal) => {
            this.createRef = modal;
          }}
          team={this.team}
        ></Create>
        <Edit
          ref={(modal) => {
            this.editRef = modal;
          }}
        ></Edit>
        <Delete
          ref={(modal) => {
            this.deleteRef = modal;
          }}
        ></Delete>

        <div className="w-100 text-right">
          <Button onClick={() => this.createRef.toggle()} color="primary">
            Create project
          </Button>
    
        </div>
        {this.state.projects === false ? (
          <MyLoader />
        ) : (
        this.state.projects.length === 0 ? (
          <h3 className="text-center">There is no projects here.</h3>
        ) : (
          <div>
            <Row>
              {this.state.projects.map((project) => {
     
                return (
                  <ProjetCard history={this.props.history} project={project} editRef={this.editRef} deleteRef={this.deleteRef} token={this.props.resource_token.resource_token}></ProjetCard>
                );
              })}
            </Row>
          </div>
        )
        )}
      </div>
    );
  }
}


const MyLoader = (props) => (
  <ContentLoader
    width={1500}
    height={800}
    viewBox="0 0 1200 575"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="350" height="350" />
    <rect x="400" y="0" rx="2" ry="2" width="350" height="350" />
    <rect x="800" y="0" rx="2" ry="2" width="350" height="350" />

    
    <rect x="0" y="425" rx="2" ry="2" width="350" height="350" />
    <rect x="400" y="425" rx="2" ry="2" width="350" height="350" />
    <rect x="800" y="425" rx="2" ry="2" width="350" height="350" />
  </ContentLoader>
);

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
});

export default connect(mapStateToProps)(All);
