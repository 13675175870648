/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import axios from "axios";
import ErrorJSON from "../utils/Error";

class Delete extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleDisable = this.handleDisable.bind(this);

    this.state = {
      modal: false,
      key: "",
      disable: false,
    };
  }

  toggle(id) {
    this.setState({ modal: !this.state.modal, id: id, disable: false }, () => {
      if (this.state.modal) {
        axios
          .get("/platform/tasks/share", { params: { id: id } })
          .then((res) => {
            this.setState({ key: res.data.key });
          })
          .catch((err) => {
            if (!err.response.data) {
              this.setState({ errors: err.message });
            } else {
              this.setState({ errors: err.response.data });
            }
          });
      }
    });
  }

  handleDisable() {
    axios
      .get("/platform/tasks/disableshare", { params: { id: this.state.id } })
      .then((res) => {
        this.setState({ disable: true });
      })
      .catch((err) => {
        if (!err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Share task</ModalHeader>

          <ModalBody>
            {this.state.errors ? (
              <ErrorJSON json={this.state.errors}></ErrorJSON>
            ) : (
              <span></span>
            )}
            You can share this prediction using this link:
            <a onClick={this.handleDisable} className="cursorp pull-right">
              Disable link
            </a>
            {this.state.disable ? (
              <div className="mt-3">
                The link has been successfully disabled.
              </div>
            ) : (
              <FormGroup className="mt-3">
                <Input
                  type="text"
                  readOnly
                  onFocus={(e) => e.target.select()}
                  value={"http://localhost:3000/share/" + this.state.key}
                />
              </FormGroup>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Delete;
