/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


import axios from "axios";
import ErrorJSON from "../../utils/Error";

class Promote extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
    };
  }

  toggle() {
    console.log("toggle");
    this.setState({ modal: !this.state.modal });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    axios
      .post("/platform/admin/users/promote", {
        id: this.props.user,
        admin: (!this.props.admin).toString(),
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {this.props.admin === true ? "Demote user" : "Promote user"}
          </ModalHeader>

          <ModalBody>
            {this.state.errors ? (
              <ErrorJSON json={this.state.errors}></ErrorJSON>
            ) : (
              <span></span>
            )}
            Do you want to {this.props.admin === true ? "demote" : "promote"}{" "}
            this user?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button
              color={this.props.admin ? "danger" : "success"}
              onClick={this.handleSubmit}
              type="submit"
            >
              Yes
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Promote;
