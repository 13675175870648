import React, { Component } from "react";
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

import { Doughnut } from "react-chartjs-2";

class ImageColors extends React.Component {
  constructor(props) {
    super(props);
    let colors = this.props.data;
    this.doughnutChartLabels = [];
    this.doughnutChartData = [];
    this.doughnutColors = [];
    colors.results.uniq_colors.forEach((element) => {
      this.doughnutChartLabels.push(element.name);
      this.doughnutChartData.push(element.coverage);
      this.doughnutColors.push(element.hexadecimal);
    });

    this.data = {
      labels: this.doughnutChartLabels,
      datasets: [
        {
          data: this.doughnutChartData,
          backgroundColor: this.doughnutColors,
        },
      ],
    };
  }

  render() {
    return (
      <Row className="border-row mt-4">
        <Col xs="2" className="py-3">
          <Row>
            <Col xs="2">
            <img src={"/img/IconPassBig.png"} className="icon-score"></img>
            </Col>
            <Col xs="8">Colors</Col>
          </Row>
        </Col>
        <Col xs="8" className="py-3">
          <div>
            <Doughnut data={this.data} />
          </div>
        </Col>
        {/* <Col xs="2" className="py-3">
          Details
        </Col> */}
      </Row>
    );
  }
}

export default ImageColors;
