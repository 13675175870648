import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";

import { setCurrentUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import LoginAzure from "./components/public/LoginAzure";
import CheckAzure from "./components/public/CheckAzure";
import Register from "./components/public/Register";
import Home from "./components/public/Home";

import Login from "./components/public/Login";
import NotFound from "./components/public/NotFound";

import Internal from "./components/layout/Internal";
import PrivateRoute from "./components/private-route/PrivateRoute";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Landing from "./components/public/Landing";
import Logout from "./components/public/Logout";
import axios from "axios";
axios.defaults.prefix = "/platform/"

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const logoutUser = () => {
  const { dispatch } = this.props;
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    logoutUser();

    // Redirect to login
    window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/connect/azure" component={LoginAzure} />
              <Route exact path="/check/azure" component={CheckAzure} />
              <Route exact path="/404" component={NotFound} />
              <Route exact path="/landing" component={Landing} />
              <PrivateRoute path="/" component={Internal} />
              <Route render={() => <Redirect to="/404" />} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
