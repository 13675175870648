import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Progress,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorJSON from "../../utils/Error";
import axios from "axios";
import Dropzone from "react-dropzone";
import modules from "./modules";

class DropzoneRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      enter: false,
      errors: false,
      modules_image: [],
      progress: [],
    };

    this.addModuleImage = this.addModuleImage.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }
  notFinished() {
    var files = this.state.files;
    if (files.length == 0) {
      return true;
    }
    for (var i = 0; i < files.length; i++) {
      if (files[i].progress !== 100) {
        return true;
      }
    }
    return false;
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.modules_image);
    this.setState({ submitted: true });

    this.state.files.map((file) => {
      axios
        .post("/platform/admin/raws/create", {
          ...this.state,
          extension: file.ext == "png" ? "jpeg" : file.ext,
          upload_id: file.id,
          project: this.state.project,
          media:
            file.ext == "mp4" ? "video" : file.ext == "html" ? "html" : "image",
          label: file.path,
          modules:
            file.ext == "mp4"
              ? this.state.modules_video
              : file.ext == "html"
              ? "html"
              : this.state.modules_image,
        })
        .then((res) => {
          window.location.href = "/admin/raws/projects/" + res.data.project;
        })
        .catch((err) => {
          this.setState({ submitted: false });

          if (!err.response || !err.response.data) {
            this.setState({ errors: err.message });
          } else {
            this.setState({ errors: err.response.data });
          }
        });
    });

    this.setState({ errors: false });
  }

  componentDidMount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  componentWillUnmount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  generateUUID() {
    var d = new Date().getTime();

    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();
    }

    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  }

  onDropRejected() {
    this.setState({ errors: { type: "This file type is not allowed." } });
  }

  send(file, start, chunkSize) {
    const chunk = file.slice(start, start + chunkSize);
    const fd = new FormData();

    fd.append("num", start);
    fd.append("data", chunk);

    return axios({
      method: "post",
      url: "/platform/tasks/upload/" + file.id,
      data: fd,
    })
      .then(() => {
        if (start + chunkSize <= file.size) {
          this.setState((prevState) => {
            return {
              files: prevState.files.map((el) =>
                el.id === file.id
                  ? { ...el, progress: parseInt((start / file.size) * 100) }
                  : el
              ),
            };
          });
          this.send(file, start + chunkSize, chunkSize);
        } else {
          axios
            .post("/platform/tasks/uploadmerge/" + file.id, {
              ext: file.ext,
            })
            .then(() => {
              this.setState((prevState) => {
                return {
                  files: prevState.files.map((el) =>
                    el.id === file.id ? { ...el, progress: 100 } : el
                  ),
                };
              });
            })
            .catch((e) => {
              this.setState((prevState) => {
                return {
                  errors: "The upload failed. Please try again later.",
                  files: prevState.files.filter((el) => el.id !== file.id),
                };
              });
            });
        }
      })
      .catch((err) => {
        this.setState((prevState) => {
          return {
            files: prevState.files.map((el) =>
              el.id === file.id ? { ...el, progress: 101 } : el
            ),
            errors: {
              ...prevState.errors,
              upload:
                "An upload failed. Please try again by refreshing the page. ",
            },
          };
        });
      });
  }

  toggle(project) {
    this.setState({ modal: !this.state.modal, project: project });
  }

  uploadFile(file) {
    var file_obj = Object.assign(file, {
      preview: URL.createObjectURL(file),
      progress: 0,
      id: this.generateUUID(),
      ext: file.type.slice(file.type.indexOf("/") + 1, file.type.length),
    });

    this.setState(
      (prevState) => ({
        files: [...prevState.files, file_obj],
      }),
      () => {
        const chunkSize = 40000;
        this.send(file_obj, 0, chunkSize);
      }
    );
  }

  addModuleImage(e) {
    var val = e.target.value;

    if (e.target.checked) {
      this.setState((prevState) => ({
        modules_image: [...prevState.modules_image, val],
      }));
    } else {
      this.setState((prevState) => ({
        modules_image: prevState.modules_image.filter((el) => el !== val),
      }));
    }
  }

  addModuleVideo(e) {
    var val = e.target.value;

    if (e.target.checked) {
      this.setState((prevState) => ({
        modules_video: [...prevState.modules_image, val],
      }));
    } else {
      this.setState((prevState) => ({
        modules_video: prevState.modules_image.filter((el) => el !== val),
      }));
    }
  }

  all(type) {
    var mods = modules[type];
    for (var i = 0; i < mods.length; i++) {
      console.log(this["ref_" + mods[i].value]);
      this[`ref_${type}_` + mods[i].value].checked = true;
      this.setState({ ["modules_" + type]: modules[type].map((a) => a.value) });
    }
  }

  onDrop(acceptedFiles) {
    if (!this.state.enter) {
      this.setState({ enter: true });
    }

    var finalFiles = acceptedFiles.slice(
      0,
      Math.min(
        Math.min(1500, acceptedFiles.length),
        1500 - this.state.files.length
      )
    );

    if (finalFiles.length !== acceptedFiles.length) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          size: "You cannot upload more than 1500 files.",
        },
      }));
    }

    finalFiles.forEach((file) => {
      console.log(file.type);
      if (["image/jpeg", "image/png"].includes(file.type)) {
        if (file.size > 1024 * 1024 * 5 && !this.props.auth.user.admin) {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              size: "File " + file.path + " is too big.",
            },
          }));
        } else {
          this.uploadFile(file);
        }
      } else {
        var video = document.createElement("video");
        video.preload = "metadata";
        var _this = this;
        video.onloadedmetadata = function () {
          var duration = video.duration;

          if (file.size > 1024 * 1024 * 30 && !_this.props.auth.user.admin) {
            _this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                size: "File " + file.path + " is too big.",
              },
            }));
          } else if (duration > 61 && !_this.props.auth.user.admin) {
            _this.setState((prevState) => ({
              errors: {
                ...prevState.errors,
                size: `Video ${file.path} is too long (${duration}s). `,
              },
            }));
          } else {
            video.remove();
            _this.uploadFile(file);
          }
        };

        video.src = URL.createObjectURL(file);
      }
    });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>New raw tasks</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              <Form>
                <h4 className="mt-0"> Modules</h4>
                <h6>
                  Images{" "}
                  <a
                    onClick={() => this.all("image")}
                    className="cursor-p small"
                  >
                    all
                  </a>
                </h6>
                {modules.image.map((module) => {
                  return (
                    <FormGroup inline check>
                      <Label check>
                        <Input
                          innerRef={(element) =>
                            (this["ref_image_" + module.value] = element)
                          }
                          onChange={this.addModuleImage}
                          value={module.value}
                          type="checkbox"
                        />{" "}
                        {module.name}
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  );
                })}

                <h6>
                  Videos{" "}
                  <a
                    onClick={() => this.all("video")}
                    className="cursor-p small"
                  >
                    all
                  </a>
                </h6>
                {modules.video.map((module) => {
                  return (
                    <FormGroup inline check>
                      <Label check>
                        <Input
                          innerRef={(element) =>
                            (this["ref_video_" + module.value] = element)
                          }
                          onChange={this.addModuleVideo}
                          value={module.value}
                          type="checkbox"
                        />{" "}
                        {module.name}
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  );
                })}
              </Form>
              <div className="dz-raw">
                <ErrorJSON json={this.state.errors}></ErrorJSON>
                <Dropzone
                  accept={["image/jpeg", "image/png", "video/mp4"]}
                  onDrop={this.onDrop}
                  onDragEnter={this.onDragEnter}
                  onDragLeave={this.onDragLeave}
                  onDropRejected={this.onDropRejected}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <h3 className="m-auto">
                        {" "}
                        <FontAwesomeIcon
                          className={
                            (this.state.files.length === 0 ? "" : "fade-out") +
                            " more-sm mr-3"
                          }
                          icon="plus-circle"
                        ></FontAwesomeIcon>
                        DROP YOUR FILES OR CLICK TO UPLOAD
                      </h3>

                      <Row className="thumbcontainer">
                        {this.state.files.map((file) => (
                          <Col
                            xs={4}
                            className={
                              (file.progress === 100
                                ? "loaded"
                                : "inprogress") + " fade-in img-thumb"
                            }
                            key={file.id}
                          >
                            <Progress
                              color={
                                file.progress === 101 ? "danger" : "warning"
                              }
                              className={
                                file.progress === 100 ? "fade-out" : ""
                              }
                              value={file.progress}
                            />
                            {file.ext === "mp4" ? (
                              <video
                                playsInline
                                autoPlay
                                muted
                                loop
                                src={file.preview}
                                className="thumb"
                              ></video>
                            ) : (
                              <img src={file.preview} className="thumb" />
                            )}
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </Dropzone>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={this.handleSubmit}
                disabled={this.state.submitted || this.notFinished()}
                type="submit"
              >
                Create
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default DropzoneRaw;
