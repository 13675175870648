import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
class DomText extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    let total_score = 0;
    if (this.props.data.results?.text_image_ratio?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.text_image_ratio?.results?.correct_ratio) {
        score = score + 1;
      }
    }
    if (this.props.data.results?.wordiness?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.wordiness?.results?.correct_text_char) {
        score = score + 1;
      }
    }
    if (this.props.data.results?.font_number?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.font_number?.results?.correct_font_number) {
        score = score + 1;
      }
    }

    let scorefinal = (score / total_score) * 100;


    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        {this.props.data.results ? (
          <>
            <Row>
              <div className="category-text">MESSAGE</div>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            {this.props.data.results?.text_image_ratio ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.text_image_ratio.results
                            ?.correct_ratio
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Ratio Text:Image</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {
                    this.props.data.results.text_image_ratio.results
                      ?.closest_ratio
                  }
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min expected ratio:{" "}
                      {
                        this.props.data.results.text_image_ratio.results
                          ?.min_expected_ratio
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max expected ratio:{" "}
                      {
                        this.props.data.results.text_image_ratio.results
                          ?.max_expected_ratio
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results.text_image_ratio.results
                    .correct_ratio ? (
                    <div>
                      {
                        this.props.data.results.text_image_ratio.results
                          .reco_pos_min_ratio
                      }{" "}
                      {
                        this.props.data.results.text_image_ratio.results
                          .reco_pos_max_ratio
                      }{" "}
                    </div>
                  ) : (
                    <div>
                      {
                        this.props.data.results.text_image_ratio.results
                          .reco_neg_min_ratio
                      }{" "}
                      {
                        this.props.data.results.text_image_ratio.results
                          .reco_neg_max_ratio
                      }
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data?.results?.wordiness ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.wordiness.results
                            .correct_text_char
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Wordiness</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  Number of characters:{" "}
                  {this.props.data.results.wordiness.results.total_text_len}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min expected characters:{" "}
                      {
                        this.props.data.results.wordiness.results
                          .min_expected_char
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max expected characters:{" "}
                      {
                        this.props.data.results.wordiness.results
                          .max_expected_char
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results.wordiness.results
                    .correct_text_char ? (
                    <div>
                      {this.props.data.results.wordiness.results.reco_pos_min}{" "}
                      {this.props.data.results.wordiness.results.reco_pos_max}{" "}
                    </div>
                  ) : (
                    <div>
                      {this.props.data.results.wordiness.results.reco_neg_min}{" "}
                      {this.props.data.results.wordiness.results.reco_neg_max}
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.font_number?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.font_number.results
                            .correct_font_number
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Font number</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.font_number.results.number_font}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min expected font:{" "}
                      {
                        this.props.data.results.font_number.results
                          ?.min_expected_font
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max expected font:{" "}
                      {
                        this.props.data.results.font_number.results
                          ?.max_expected_font
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results.font_number.results
                    .correct_font_number ? (
                    <div>
                      {this.props.data.results.font_number.results.reco_pos_min}{" "}
                      {this.props.data.results.font_number.results.reco_pos_max}{" "}
                    </div>
                  ) : (
                    <div>
                      {this.props.data.results.font_number.results.reco_neg_min}{" "}
                      {this.props.data.results.font_number.results.reco_neg_max}
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.text_accessibility?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.text_accessibility.results
                            .text_accessible
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Font readability</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.text_accessibility?.results
                    .text_non_accessible_list ? (
                    <>
                      <Row>
                        <Col>
                          Number of non-readable text:{" "}
                          {Math.round(
                            this.props.data.results.text_accessibility.results
                              ?.text_non_accessible_list.length
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col></Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default DomText;
