import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import {Cube} from "../tabs/report"
class ImageTextRatio extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let scorefinal = this.props.data.results.correct_ratio.results.score
     

    this.props.generateScore(scorefinal);
  }

  render() {
    return  (
      <>            <Row className="mt-2">
          <Col className="py-2" xs="4"></Col>
          <Col className="py-2 text-title dark-background" xs="3">
            RESULT
          </Col>
          <Col className="py-2 text-title dark-background" xs="3">
            RECOMMENDED
          </Col>
          <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.correct_ratio.results.correct_ratio
                  }
                ></Cube>
              </Col>
              <Col xs="10">Ratio Image:Text</Col>
            </Row>
          </Col>
          <Col xs="3" className="py-3 dark-background">
            {this.props.data.results.correct_ratio.results.closest_ratio}
          </Col>
          <Col xs="3" className="py-3 dark-background">
            <Row>
              <Col>
                Min Ratio:{" "}
                {
                  this.props.data.results.correct_ratio.results
                    .min_expected_ratio
                }
              </Col>
            </Row>
            <Row>
              <Col>
                Max Ratio:{" "}
                {
                  this.props.data.results.correct_ratio.results
                    .max_expected_ratio
                }
              </Col>
            </Row>
          </Col>
          <Col xs="2" className="py-3">
            {this.props.data.results.correct_ratio.results.correct_ratio ? (
              <div>
                {
                  this.props.data.results.correct_ratio.results
                    .reco_pos_min_ratio
                }{" "}
                {
                  this.props.data.results.correct_ratio.results
                    .reco_pos_max_ratio
                }{" "}
               
              </div>
            ) : (
              <div>
                {
                  this.props.data.results.correct_ratio.results
                    .reco_neg_min_ratio
                }{" "}
                {
                  this.props.data.results.correct_ratio.results
                    .reco_neg_max_ratio
                }{" "}
              
              </div>
            )}
          </Col>
        </Row>

     
      </>
    );
  }
}



export default ImageTextRatio;
