import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Previews from "./Dropzone";
import URL from "./URL";
class Create extends React.Component {
  constructor(props) {
    super(props);
    this.expand = this.expand.bind(this);
    this.state = {
      drop: true,
      url: true,
    };
  }

  expand(type) {
    if (type === "drop") {
      this.setState({ url: false });
    } else if (type === "reset") {
      this.setState({ url: true, drop: true });
    } else {
      this.setState({ drop: false });
    }
  }

  render() {
    return (
      <Row>
        <Col className={!this.state.drop ? "col-0" : ""}>
          <Previews expand={this.expand}></Previews>
        </Col>
        <Col className={!this.state.url ? "col-0" : ""}>
          <URL expand={this.expand}></URL>
        </Col>
      </Row>
    );
  }
}

export default Create;
