import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import All from "./Index";
import ManageIndex from "./ManageIndex";
import ManageTeam from "./ManageTeam";

class TeamRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/teams/manage" component={ManageIndex} />
        <Route exact path="/teams/manage/:id" component={ManageTeam} />
        <Route exact path="/teams/:id" component={All} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    );
  }
}

export default TeamRoutes;
