import React, { Component } from "react";
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";
import { Cube } from "../tabs/report";
class DomLogo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.data?.results?.logo_coord ? (
          <>
        
            <Row className="mt-4">
              <Col className="py-2" xs="4">Logo</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results?.logo_coord?.results?.has_logo
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Logo detection</Col>
                </Row>{" "}
              </Col>

              <Col xs="3" className="py-3 dark-background">
                {this.props.data.results?.logo_coord?.results?.has_logo == false
                  ? "No logo has been detected"
                  : "A logo has been detected"}
              </Col>
              <Col xs="3" className="py-3 dark-background">
                {"dom_logo" in this.props.data?.attachments ? (
                  <img
                    src={`/platform/attachments/serveattachment?name=dom_logo&id=${this.props.id}&token=${this.props.token}`}
                  ></img>
                ) : (
                  <span></span>
                )}{" "}
              </Col>
              <Col className="py-3 " xs="2">
              { this.props.data.results?.logo_coord?.results?.has_logo ? (
                  <div>{this.props.data.results?.logo_coord?.results?.reco_pos_log}</div>
                ) : (
                  <div>{this.props.data.results?.logo_coord?.results?.reco_neg_logo}</div>
                )}
              </Col>
            </Row>

            {this.props.data.results?.logo_coord?.results?.has_logo ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results?.logo_coord?.results
                            ?.correctly_placed
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Logo placement</Col>
                  </Row>{" "}
                </Col>

                <Col xs="3" className="py-3 dark-background">
                  {" "}
                  {this.props.data.results?.logo_coord?.results
                    ?.correctly_placed == false
                    ? "The logo is not correctly placed, it should be in the top-left corner of the page."
                    : "The logo is correctly placed in the top-left corner of the page."}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {"dom_logo" in this.props.data?.attachments ? (
                    <img
                      src={`/platform/attachments/serveattachment?name=dom_logo&id=${this.props.id}&token=${this.props.token}`}
                    ></img>
                  ) : (
                    <span></span>
                  )}
                </Col>
                <Col xs="2" className="py-3">
                {this.props.data.results?.logo_coord?.results
                    ?.correctly_placed? (
                              <div>{this.props.data.results?.logo_coord?.results
                                ?.reco_pos_top} {" "} {this.props.data.results?.logo_coord?.results
                                  ?.reco_pos_left} </div>
                            ) : (
                              <div>{this.props.data.results?.logo_coord?.results
                                ?.reco_neg_top} {" "} {this.props.data.results?.logo_coord?.results
                                  ?.reco_neg_left}</div>
                            )}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Row className="border-row mt-4 no-top-border">
              <Col className="py-2 text-title" xs="4">
                Logo
              </Col>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            <Row>
              <Col xs="4" className="py-2">
                <Row>
                  <Col xs="2">
                    <Cube color={false}></Cube>
                  </Col>
                  <Col xs="10">Logo detection</Col>
                </Row>{" "}
              </Col>

              <Col xs="3" className="py-3 dark-background">
                No logo could be detected in the page.
              </Col>

              <Col className="py-3 dark-background" xs="3"></Col>
              <Col className="py-3 " xs="2">{this.props.data.results.logo_coord.results.reco_neg_logo}</Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default DomLogo;
