import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class ImageObjects extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <InfoModal
          ref={(modal) => {
            this.infoModalRef = modal;
          }}
        ></InfoModal>
        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Objects
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 text-title dark-background">
                NAME
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                SIZE
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                VISIBILITY{" "}
                <FontAwesomeIcon
                  onClick={() => this.infoModalRef.toggle()}
                  className="info-icon"
                  icon="info-circle"
                ></FontAwesomeIcon>
              </Col>
            </Row>
          </Col>
          <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col>
        </Row>
        <Row className="border-row">
          <Col xs="4" className="py-3">
            <div>
              <LazyLoadImage
                placeholderSrc={
                  "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                }
                effect="blur"
                className="card-img-results"
                src={`/platform/attachments/serveattachment?name=imageobject&id=${this.props.id}&token=${this.props.token}`}
              />
            </div>
          </Col>
          <Col xs="6" className="py-3 dark-background">
            {this.props.data.results.object_size.results.map(function (object) {
              return (
                <Row>
                  <Col xs="6">{object.name}</Col>
                  <Col xs="3">{Math.round(object.sizePercent)}%</Col>
                  <Col xs="3">{Math.round(object.visibility)}</Col>
                </Row>
              );
            })}
          </Col>
          <Col xs="2" className="py-3">
            {this.props.data.results?.recommendation?.results?.reco_pos}
          </Col>
        </Row>
      </>
    );
  }
}

class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      modal: false,
    };
  }

  toggle(project) {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Object visibility</ModalHeader>

          <ModalBody>
            The visibility of the object is calculated by calculating the average of
            the visual attention score for each pixel of the object. The pixel of the object are the ones outlined by the red rectangle  <br></br>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ImageObjects;
