import React from "react";
import { authProvider } from "../../adal";
import { AzureAD } from "react-aad-msal";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LoginAzure extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;

    if (this.props.auth.isAuthenticated) {
      window.location.href = "/home";
    }

    try {
      var token = await authProvider.getAccessToken();

      if (token.accessToken) {
        console.log("token.accessToken", token.accessToken);
        axios
          .post("/platform/public/login-azure", { token: token.accessToken })
          .then((res) => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);

            setAuthToken(token);
    

            const decoded = jwt_decode(token);

            dispatch(setCurrentUser(decoded));

            var cookies = cookie.loadAll();
            Object.keys(cookies).forEach(function (key) {
              console.log(key);
              cookie.remove(key, { path: "/" });
            });

            Object.keys(localStorage).forEach(function (key) {
              if (key != "jwtToken") {
                localStorage.removeItem(key);
              }
            });

            this.props.history.push("/home");
          })
          .catch((err) => {
            // this.props.history.push("/login");
          });
      }
    } catch {
      console.log("Error retrieving token");
    }
  }

  render() {
    return (
      <AzureAD provider={authProvider} forceLogin={true}>
        <div className="azure-bg">
          <FontAwesomeIcon
            icon="spinner"
            className="spinner-rotate spinner-loggin"
          ></FontAwesomeIcon>
        </div>
      </AzureAD>
    );
  }
}

LoginAzure.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(LoginAzure);
