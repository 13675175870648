import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Progress,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import axios from "axios";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }
  componentDidMount() {
    console.log("title", this.props.title);
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }
  render() {
    const { user } = this.props.auth;
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-text-dark navbar-absolute fixed-top"
            : "navbar-text-dark navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-between"
          >
            {/* <form style={{ width: "50%" }}>
              <InputGroup className="no-border xl-input">
                <Input placeholder="Search..." />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FontAwesomeIcon icon="search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form> */}

            <span id="page-title">
              {" "}
              <FontAwesomeIcon
                className="mr-3"
                icon={this.props.title.icon}
              />{" "}
              {this.props.title.title}
            </span>
            <div>
              <Nav navbar className="">
                <Dropdown
                  nav
                  isOpen={this.state.dropdownOpen}
                  toggle={(e) => this.dropdownToggle(e)}
                >
                  <DropdownToggle caret nav>
                    <FontAwesomeIcon className="mr-3" icon="user" />
                    {user.full_name}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem href="/profile" tag="a">
                      My profile
                    </DropdownItem>

                    <Quotas user={user}></Quotas>
                    <DropdownItem href="/logout" tag="a">
                      Log out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </div>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const Quotas = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/platform/users/getquotas");

      setData(result.data.user);
    };

    fetchData();
  }, []);

  return (
    <div className="wrapper-quotas">
      <div className="w-100 small">
        <span className="pull-left">Images</span>{" "}
        <span className="pull-right">
          {data.current_quota_image}/{data.quota_image}
        </span>
      </div>
      <Progress
        className="w-100"
        color={
          (data.current_quota_image / data.quota_image) * 100 > 80
            ? "danger"
            : (data.current_quota_image / data.quota_image) * 100 > 60
            ? "warning"
            : "info"
        }
        value={(data.current_quota_image / data.quota_image) * 100}
      />
      <div className="w-100 small mt-2">
        <span className="pull-left">Videos</span>{" "}
        <span className="pull-right">
          {data.current_quota_video}/{data.quota_video}
        </span>
      </div>
      <Progress
        className="w-100"
        color={
          (data.current_quota_video / data.quota_video) * 100 > 80
            ? "danger"
            : (data.current_quota_video / data.quota_video) * 100 > 60
            ? "warning"
            : "info"
        }
        value={(data.current_quota_video / data.quota_video) * 100}
      />
       <div className="w-100 small mt-2">
        <span className="pull-left">HTML</span>{" "}
        <span className="pull-right">
          {data.current_quota_dom}/{data.quota_dom}
        </span>
      </div>
      <Progress
        className="w-100"
        color={
          (data.current_quota_dom / data.quota_dom) * 100 > 80
            ? "danger"
            : (data.current_quota_dom / data.quota_dom) * 100 > 60
            ? "warning"
            : "info"
        }
        value={(data.current_quota_dom / data.quota_dom) * 100}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  title: state.title,
});

export default connect(mapStateToProps)(Header);
