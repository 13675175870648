import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
class DomForm extends React.Component {
  constructor(props) {
    super(props);
    // this.generateScore();

    console.log(this.props.data.results);
  }

  // generateScore() {
  //   let score = 0;
  //   let total_score = 0;
  //   if (this.props.data.results?.text_image_ratio?.results) {
  //     total_score = total_score + 1;
  //     if (this.props.data.results.text_image_ratio?.results?.correct_ratio) {
  //       score = score + 1;
  //     }
  //   }
  //   if (this.props.data.results?.wordiness?.results) {
  //     total_score = total_score + 1;
  //     if (this.props.data.results.wordiness?.results?.correct_text_char) {
  //       score = score + 1;
  //     }
  //   }
  //   if (this.props.data.results?.font_number?.results) {
  //     total_score = total_score + 1;
  //     if (this.props.data.results.font_number?.results?.correct_font_number) {
  //       score = score + 1;
  //     }
  //   }

  //   let scorefinal = (score / total_score) * 100;

  //   this.props.generateScore(scorefinal);
  // }

  render() {
    return (
      <>
        {this.props.data.results ? (
          <>
            <Row>
              <div className="category-text">FORM</div>
            </Row>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            {this.props.data.results?.number_field?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.number_field.results
                            .correct_number
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Number of field</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  Number of fields:{" "}
                  {this.props.data.results.number_field.results?.number_field}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  Max number of fields:{" "}
                  {this.props.data.results.number_field.results?.max_field}
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.input_type ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    {this.props.data.results.input_type.results?.counter > 0 ? (
                      <>
                        <Col xs="2">
                          <Cube
                            color={
                              this.props.data.results.input_type.results
                                ?.correct
                            }
                          ></Cube>
                        </Col>
                        <Col xs="10">Specific input types</Col>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Col xs="2">
                          <Cube color={false}></Cube>
                        </Col>{" "}
                        <Col xs="10">No input type</Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.input_type.results?.counter > 0 ? (
                    <>
                      {" "}
                      {Math.round(
                        this.props.data.results.input_type.results
                          ?.average_correct * 100
                      )}{" "}
                      % correct input type{" "}
                    </>
                  ) : (
                    <>No input type</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Number of inputs with a type:{" "}
                      {this.props.data.results.input_type.results?.counter}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Number of correct inputs:{" "}
                      {
                        this.props.data.results.input_type.results
                          ?.correct_type_number
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data?.results?.one_column_layout ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.one_column_layout.results
                            .correct_one_column
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">One Column Layout</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.one_column_layout.results
                    .correct_one_column ? (
                    <>There is a single column</>
                  ) : (
                    <>There are more than one column</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>A single column is expected</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.top_left_label?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.top_left_label.results
                            .top_left_label
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Top Left Labels</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  Number of misplaced labels:{" "}
                  {Math.round(
                    this.props.data.results.top_left_label.results?.uncorrect
                      .length
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>Labels should be placed on the top left</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.captcha?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={this.props.data.results.captcha.results.correct}
                      ></Cube>
                    </Col>
                    <Col xs="10">Captcha</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.captcha.results
                    ?.captacha_presence ? (
                    <>A captcha is present</>
                  ) : (
                    <>There is no captcha on the page</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.captcha.results?.captcha_expected ? (
                    <>A captcha should be present</>
                  ) : (
                    <>There shouldn't be a captcha</>
                  )}
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.optional_field?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.optional_field.results
                            .correct_optional
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Optional Fields</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.optional_field.results.counter >
                  0 ? (
                    <>
                      {" "}
                      Field correctly labeled as optional:{" "}
                      {Math.round(
                        this.props.data.results.optional_field.results
                          ?.average_correct * 100
                      )}
                      %
                    </>
                  ) : (
                    <>There are no field labeled as optional</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col> Field labeled as optional should be optional</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.input_size?.results ? (
              <>
                {this.props.data.results?.input_size?.results.counter > 0 ? (
                  <Row className="border-row">
                    <Col xs="4" className="py-3">
                      <Row>
                        <Col xs="2">
                          <Cube
                            color={
                              this.props.data.results.input_size.results
                                .correct_size
                            }
                          ></Cube>
                        </Col>
                        <Col xs="10">Correct Input Size</Col>
                      </Row>
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      Input with an appropriate size:{" "}
                      {Math.round(
                        this.props.data.results.input_size.results
                          ?.average_correct * 100
                      )}
                      %
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      <Row>
                        <Col>Input should have an height of at least 48px</Col>
                      </Row>
                    </Col>
                    <Col xs="2" className="py-3"></Col>
                  </Row>
                ) : (
                  <></>
                )}{" "}
              </>
            ) : (
              <></>
            )}

            {this.props.data.results?.font_size?.results ? (
              <>
                {this.props.data.results?.font_size?.results.counter > 0 ? (
                  <Row className="border-row">
                    <Col xs="4" className="py-3">
                      <Row>
                        <Col xs="2">
                          <Cube
                            color={
                              this.props.data.results.font_size.results
                                .correct_font
                            }
                          ></Cube>
                        </Col>
                        <Col xs="10">Correct Font Size</Col>
                      </Row>
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      Input with an appropriate font size:{" "}
                      {Math.round(
                        this.props.data.results.font_size.results
                          ?.average_correct * 100
                      )}
                      %
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      <Row>
                        <Col>
                          The placeholder and label font should be superior or
                          equal to 16px.
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="2" className="py-3"></Col>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {this.props.data.results?.cookie_info?.results ? (
              <>
                {this.props.data.results?.cookie_info?.results
                  .cookie_presence ? (
                  <Row className="border-row">
                    <Col xs="4" className="py-3">
                      <Row>
                        <Col xs="2">
                          <Cube
                            color={
                              this.props.data.results.cookie_info.results
                                .one_line
                            }
                          ></Cube>
                        </Col>
                        <Col xs="10">One Line Cookie info</Col>
                      </Row>
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      Length in character of the cookie information:{" "}
                      {Math.round(
                        this.props.data.results.cookie_info.results
                          ?.character_number
                      )}
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      <Row>
                        <Col>The cookie information should fit in one line</Col>
                      </Row>
                    </Col>
                    <Col xs="2" className="py-3"></Col>
                  </Row>
                ) : (
                  <Row className="border-row">
                    <Col xs="4" className="py-3">
                      <Row>
                        <Col xs="2">
                          <Cube
                            color={
                              this.props.data.results?.cookie_info?.results
                                .cookie_presence
                            }
                          ></Cube>
                        </Col>
                        <Col xs="10">Presence of cookie information</Col>
                      </Row>
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      There should be a line of information on cookies
                    </Col>
                    <Col xs="3" className="py-3 dark-background">
                      <Row>
                        <Col>The cookie information should fit in one line</Col>
                      </Row>
                    </Col>
                    <Col xs="2" className="py-3"></Col>
                  </Row>
                )}
              </>
            ) : (
              <></>
            )}

            {this.props.data.results?.headline?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.headline.results
                            .headline_existing
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Form Headline</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.headline.results
                    .headline_existing ? (
                    <>The form has an headline</>
                  ) : (
                    <> The form does not have an headline</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>Every step form should have an headline.</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.help_me?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.help_me.results.help_button
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Help me feature</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.help_me.results.help_button ? (
                    <>The form has a help me feature</>
                  ) : (
                    <> The form does not have a help me feature</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>The form should have an help me feature</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.link_logo?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.link_logo.results.header_logo
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Logo link to homepage</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.link_logo.results.header_logo ? (
                    <>The page has a logo linking to the homepage</>
                  ) : (
                    <> The page does not have a logo linking to the homepage</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      The logo in the header should link to the homepage
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.progress_bar?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.progress_bar.results
                            .progress_bar
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Progress Bar Presence</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.progress_bar.results.progress_bar ? (
                    <>The page has a progress bar</>
                  ) : (
                    <> The page does not have a progress bar</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>Multiple screen forms should have a progress bar</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.contact_details?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.contact_details.results.sensitive_information
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Presence of sensitive information</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  
                 <Row>  <Col> {
                        this.props.data.results.contact_details.results?.step_number != "" ?
                      (<>Step number:{" "}
                      {Math.round(
                        this.props.data.results.contact_details.results?.step_number
                      )}
                     </>)

                    :(<>The step number is not mentionned </>)} </Col></Row>
                    <Row> <Col>

                       {
                        this.props.data.results.contact_details.results?.sensitive_information ?
                      (<> Sensitive information are present
                     </>)

                    :(<>Sensitive information are not present </>)} </Col></Row>

                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Sensitive information should be requested in the final
                      steps
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.phone_first_step?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                    {
                        this.props.data.results.phone_first_step.results
                          ?.step_number != "" ?
                      (<>
                     <Cube
                        color={
                          this.props.data.results.phone_first_step.results
                            .correct_first_step
                        }
                      ></Cube></>)

                    :(<><Cube
                      color="maybe"
                    ></Cube> </>)}
                      
                    </Col>
                    <Col xs="10">Correct First Step</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      {this.props.data.results.phone_first_step.results
                        ?.phone_number ? (
                        <>A phone number input is present</>
                      ) : (
                        <>There is no phone number input</>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    {
                        this.props.data.results.phone_first_step.results
                          ?.step_number != "" ?
                      (<>
                      {" "}
                      Step number:{" "}
                      {
                        this.props.data.results.phone_first_step.results
                          ?.step_number
                      }</>)

                    :(<>The step number is not mentionned </>)}
                    </Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      The phone number should not be requested in the first step
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.tool_tip?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.tool_tip.results.tool_tips
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Tooltips Presence</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {" "}
                  {this.props.data.results.tool_tip.results.tool_tips
                    .progress_bar ? (
                    <>The page has tooltips</>
                  ) : (
                    <> The page does not have tooltips</>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>Tooltips should be present to help the user</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default DomForm;
