import React, { Component } from "react";
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";
import { Cube } from "../tabs/report";
class DomNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    let total_score = 0;
    if (this.props.data.results.navigation_coord?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.navigation_coord?.results?.has_nav) {
        score = score + 1;
      }
    }
    if (this.props.data.results.navigation_coord?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.navigation_coord?.results?.correctly_placed) {
        score = score + 1;
      }
    }
    if (this.props.data.results.search_presence?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.search_presence?.results?.has_search) {
        score = score + 1;
      }
    }

    let scorefinal = (score / total_score) * 100;

    this.props.generateScore(scorefinal);
  }

  getWordsList() {
    if (this.props.data.results.navigation_coord.results.has_nav == false) {
      return false;
    }
    var words_list = this.props.data.results.navigation_words.results;

    var status = "failed";
    var list = false;

    for (var i = 0; i < words_list["nav_words_present"].length; i++) {
      if (
        words_list["nav_words_present"][i].length ==
        words_list["nav_words_required"][i].length
      ) {
        status = "success";
        list = i;
        console.log("success");
        break;
      } else if (words_list["nav_words_present"][i].length > 0) {
        status = "partial";
        list = i;
        break;
      }
    }
    console.log("STATUS", status);

    var capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    var getListString = (index) => {
      var required = this.props.data.results.navigation_words.results
        .nav_words_required[index];

      var present = this.props.data.results.navigation_words.results
        .nav_words_present[index];

      console.log("present", present);
      console.log("required", required);

      var s = "<b>Required: </b> ";
      for (var i = 0; i < required.length; i++) {
        i > 0 ? (s += ` - `) : (s += "");
        s += capitalizeFirstLetter(required[i]);
      }

      s += "<br> <b>Present: </b> ";
      if(present.length == 0){
        s += "None"
      }
      for (var i = 0; i < present.length; i++) {
        i > 0 ? (s += ` - `) : (s += "");
        s += capitalizeFirstLetter(present[i]);
      }
      
      return s;
    };

    if (status == "success") {
      var mess = `The navigation element includes links to all the needed pages.`;

      var reco =  ` ${getListString(
        list
      )}`;

      return {
        status: true,
        mess: mess,
        reco:reco,
      };
    } else if (status == "partial") {
      var mess = `The navigation element includes links to some of the needed pages`;

      var reco =  `${getListString(
        list
      )}`;

      return {
        status: "maybe",
        mess: mess,
        reco:reco,
      };
    } else {
      var mess = `The navigation element does not include links to any of the needed pages`;

      var reco =  `${getListString(
        0
      )}`;

      return {
        status: false,
        mess: mess,
        reco:reco,
      };
    }
  }

  render() {
    var words_results = this.getWordsList();
    return (
      <>
        {this.props.data?.results?.navigation_coord ? (
          <>

            <Row>
              <Col className="py-2" xs="4">  Navigation</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.navigation_coord.results.has_nav
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Navigation detection</Col>
                </Row>
              </Col>

              <Col xs="3" className="py-3 dark-background">
                {this.props.data.results.navigation_coord.results.has_nav ==
                false
                  ? "No navigation element has been detected"
                  : "A navigation element has been detected"}
              </Col>
              <Col xs="3" className="py-3 dark-background">
                <Row></Row>
              </Col>{" "}
              <Col xs="2" className="py-3">
                { this.props.data.results.navigation_coord.results.has_nav ? (
                  <div>{this.props.data.results.navigation_coord.results.reco_pos_nav}</div>
                ) : (
                  <div>{this.props.data.results.navigation_coord.results.reco_neg_nav}</div>
                )}
              </Col>
            </Row>

            {this.props.data.results.navigation_coord.results.has_nav ? (
              <>
                <Row className="border-row">
                  <Col xs="4" className="py-3">
                    <Row>
                      <Col xs="2">
                        <Cube
                          color={
                            this.props.data.results.navigation_coord.results
                              .correctly_placed
                          }
                        ></Cube>
                      </Col>
                      <Col xs="10">Navigation placement</Col>
                    </Row>{" "}
                  </Col>

                  <Col xs="3" className="py-3 dark-background">
                    {this.props.data.results.navigation_coord.results
                      .correctly_placed == false
                      ? "The navigation element is not correctly placed, it should be at the top of the page."
                      : "The navigation element is correctly placed at the top of the page."}
                  </Col>
                  <Col xs="3" className="py-3 dark-background">
                    <Row></Row>
                  </Col>{" "}
                  <Col xs="2" className="py-3">
                  { this.props.data.results.navigation_coord.results.correctly_placed ? (
                  <div>{this.props.data.results.navigation_coord.results.reco_pos}</div>
                ) : (
                  <div>{this.props.data.results.navigation_coord.results.reco_neg}</div>
                )}
                  </Col>
                </Row>
                
                <Row className="border-row">
                  <Col xs="4" className="py-3">
                    <Row>
                      <Col xs="2">
                        <Cube color={words_results.status}></Cube>
                      </Col>
                      <Col xs="10">Navigation links</Col>
                    </Row>{" "}
                  </Col>

                  <Col xs="3" className="py-3 dark-background">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: words_results.mess,
                      }}
                    ></span>
                  </Col>

                  <Col className="py-3 dark-background" xs="3">
                    {" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: words_results.reco,
                      }}
                    ></span>
                  </Col>
                  <Col className="py-3 " xs="2">
                  { words_results.status? (
                  <div>{this.props.data.results.navigation_words.results.reco_pos}</div>
                ) : (
                  <div>{this.props.data.results.navigation_words.results.reco_neg}</div>
                )}

                  </Col>
                </Row>
              </>
            ) : (
              <span></span>
            )}
          </>
        ) : (
          <>
 
            <Row  className="border-row ">
              <Col className="py-2" xs="4">Navigation</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            <Row>
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube color={false}></Cube>
                  </Col>
                  <Col xs="10">Navigation detection</Col>
                </Row>{" "}
              </Col>

              <Col xs="3" className="py-3 dark-background">
                No navigation could be detected in the page.
              </Col>

              <Col className="py-3 dark-background" xs="3"></Col>
              <Col className="py-3 " xs="2"></Col>
            </Row>
          </>
        )}

        {this.props.data?.results?.search_presence ? (
          <>
     
            <Row className="mt-4">
              <Col className="py-2" xs="4">Search</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
        </Row>
           

            <Row className="border-row">
              <Col xs="4" className="py-3">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.search_presence.results
                          .has_search
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Search detection</Col>
                </Row>{" "}
              </Col>

              <Col xs="3" className="py-3 dark-background">
                {this.props.data.results.search_presence.results.has_search ==
                false
                  ? "No search bar has been detected"
                  : "A search bar has been detected"}
              </Col>
              <Col xs="3" className="py-3 dark-background">
                <Row></Row>
              </Col>{" "}
              <Col xs="2" className="py-3">
                {this.props.data.results.search_presence.results
                          .has_search ? (
                 <div>{this.props.data.results.search_presence.results
                   .reco_pos}</div>
               ) : (
                 <div>{this.props.data.results.search_presence.results
                   .reco_neg}</div>
               )}
              </Col>
            </Row>
          </>
        ) : (
          <>

            <Row className="border-row">
              <Col className="py-2" xs="4">Search</Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            <Row>
              <Col xs="4" className="py-2">
                <Row>
                  <Col xs="2">
                    <Cube color={false}></Cube>
                  </Col>
                  <Col xs="10">Search detection</Col>
                </Row>{" "}
              </Col>

              <Col xs="3" className="py-3 dark-background">
                No search bar could be detected in the page.
              </Col>

              <Col className="py-3 dark-background" xs="3"></Col>
              <Col className="py-3 " xs="2"> 

                 <div>{this.props.data.results.search_presence.results
                   .reco_neg}</div>
               </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default DomNavigation;
