/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import striptags from "striptags";

import axios from "axios";
import ErrorJSON from "../utils/Error";

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.team = this.props.team;

    this.state = {
      modal: false,
    };
  }

  toggle() {
    console.log("toggle");
    this.setState({ modal: !this.state.modal });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val) });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    axios
      .post("/platform/projects/create", {
        name: this.state.name,
        description: this.state.description,
        team: this.team,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Create new project</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <FormGroup>
                <Label for="name">Project name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.handleChange}
                  required
                  maxLength={30}
                  placeholder="Enter a name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Project description (optional)</Label>
                <Input
                  maxLength={120}
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={this.handleChange}
                  placeholder="Describe your project here"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Create
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Create;
