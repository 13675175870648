import React from "react";
import CreateProject from "./CreateProject";
import axios from "axios";
import { Row, Button, Col, Card, CardBody, CardImg, CardTitle } from "reactstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Delete from "./Delete"
import { setPageTitle } from "../../../actions/titleActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class IndexProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
    };
  }

  fetchData() {
  
    axios
      .get("/platform/admin/raws/getprojects")
      .then((res) => {
        this.setState({
          projects: res.data.projects,
        });
      })
      .catch((err) => {
        // this.props.history.push("/home");
      });
  }

  componentDidMount() {
    const { dispatch} = this.props
    dispatch(setPageTitle("Raws", "server"));
    this.fetchData();
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{`CRISP - Raw`}</title>
        </Helmet>
        <Delete
          ref={(modal) => {
            this.deleteRef = modal;
          }}
        ></Delete>

        <CreateProject
          ref={(modal) => {
            this.createRef = modal;
          }}
          team={this.team}
        ></CreateProject>
        <div className="w-100 text-right">
          <Button onClick={() => this.createRef.toggle()} color="primary">
            Create project
          </Button>
        </div>
        {this.state.projects.length === 0 ? (
          <h3 className="text-center">There is no projects here.</h3>
        ) : (
          <div>
            <Row>
              {this.state.projects.map((project) => {
                  return (
          <Col xs="4">
          <Card className="fade-in">
            <CardImg
              onClick={() =>
                this.props.history.push("/admin/raws/projects/" + project._id)
              }
              top
              width="100%"
              className="card-img cursorp"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/img/loading.gif";
              }}
              src={
                project.last_task === "none"
                  ? "https://www.larcorporel.com/wp-content/uploads/2019/10/placeholder.png"
                  : `/platform/attachments/servethumbraw?id=${project.last_task._id}&token=${this.props.resource_token.resource_token}`
              }
              alt="Card image cap"
            />
            <CardBody>
              <CardTitle className="project-title">{project.name}</CardTitle>
              <FontAwesomeIcon
              onClick={() => this.deleteRef.toggle(project._id)}
              className="icon-card clickable pull-right"
              title="Delete project"
              icon="trash"
            />
            </CardBody>
          </Card>
        </Col>)
              })}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
});



export default connect(mapStateToProps)(IndexProjects);

