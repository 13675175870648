import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
class DomRendering extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row id="rendering" className="mt-4">
        <Col xs="12 fade-in">
          <Row>
            <div className="category-text">DEVICE RENDERING SIMULATION</div>
          </Row>

          <ModalRendering
            ref={(modal) => {
              this.modal = modal;
            }}
            id={this.props.id}
            token={this.props.token}
          ></ModalRendering>
          <Row className="border-row mt-4 no-top-border">
            <Col xs="2" className="py-2">
              <RenderItem
                onClick={() => this.modal.toggle(null, "mobile")}
                id={this.props.id}
                token={this.props.token}
                device="mobile"
              ></RenderItem>
            </Col>

            <Col xs="3" className="py-2">
              <RenderItem
                onClick={() => this.modal.toggle(null, "tablet")}
                id={this.props.id}
                token={this.props.token}
                device="tablet"
              ></RenderItem>
            </Col>
            <Col xs="7" className="py-2">
              <RenderItem
                onClick={() => this.modal.toggle(null, "desktop")}
                id={this.props.id}
                token={this.props.token}
                device="desktop"
              ></RenderItem>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const RenderItem = (props) => {
  return (
    <div className="rendering-container">
      <img className="rendering-device" src={`/img/${props.device}.png`}></img>
      <div
        className={`rendering-content-container ${props.device} ${props.classAdd}`}
      >
        <img></img>

        <LazyLoadImage
          placeholderSrc={
            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
          }
          effect="blur"
          onClick={props.onClick}
          className="rendering-content"
          src={`/platform/attachments/serveattachment?name=${props.device}&id=${props.id}&token=${props.token}`}
        />
      </div>
    </div>
  );
};

class ModalRendering extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      device: false,
    };
  }

  toggle(e, device) {
    if (device != false) {
      this.setState({ modal: !this.state.modal, device: device });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  }

  render() {
    return (
      <div>
        <Modal
          size={
            this.state.device == "desktop"
              ? "xl"
              : this.state.device === "tablet"
              ? "md"
              : "sm"
          }
          className="modal-rendering"
          centered={true}
          isOpen={this.state.modal}
          toggle={() => this.toggle(null, false)}
        >
          <RenderItem
            id={this.props.id}
            token={this.props.token}
            device={this.state.device}
            classAdd="modalbox"
          ></RenderItem>
        </Modal>
      </div>
    );
  }
}

export default DomRendering;
