import React, { useEffect, useState, useRef } from "react";
import Create from "./Create";
import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import { Col } from "reactstrap";
import axios from "axios";
import TaskCard from "../task/TaskCard";
import Edit from "../task/Edit";
import Delete from "../task/Delete";
import Share from "../task/Share";
import EditProject from "../project/Edit";
import DeleteProject from "../project/Delete";
import ProjectCard from "../project/ProjectCard";
import ContentLoader from "react-content-loader";

const Dashboard = (props) => {
  useEffect(() => {
    const { dispatch } = props;
    dispatch(setPageTitle("Dashboard", "home"));
  });

  return (
    <div className="content">
      <Helmet>
        <title>CRISP - Dashboard</title>
      </Helmet>
      <Create></Create>

      <LastPredictions
        access={props.access}
        history={props.history}
        token={props.resource_token.resource_token}
      ></LastPredictions>

      <LastProjects
        history={props.history}
        token={props.resource_token.resource_token}
      ></LastProjects>
    </div>
  );
};

const LastPredictions = (props) => {
  const [data, setData] = useState(false);
  const editRef = useRef(null);
  const shareRef = useRef(null);
  const deleteRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/platform/tasks/getlastsforuser");

      setData(result.data.tasks);
    };

    fetchData();
  }, []);

  const ref = React.createRef(null);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div className="mt-4">
      <Edit access={props.access} history={props.history} ref={editRef}></Edit>
      <Share ref={shareRef}></Share>
      <Delete ref={deleteRef}></Delete>

      <h3>Your last tasks</h3>
      <div className="mt-4 position-relative">
        {data === false ? (
          <MyLoader />
        ) : data.length == 0 ? (
          <div className="text-center">
            <h4>You haven't made any tasks.</h4>
          </div>
        ) : (
          <div>
            <div className="gradient-right"></div>
            <div className="gradient-left"></div>
            <div className="container-scroll" ref={ref}>
              <div class="chevron-height">
                <div class="chevron-left" onClick={() => scroll(-70)}>
                  <i class="fas fa-chevron-left"></i>
                </div>
              </div>
              <div className="chevron-spacing">&nbsp;</div>
              {data.map((task) => (
                <TaskCard
                  key={task._id}
                  token={props.token}
                  history={props.history}
                  editRef={editRef.current}
                  shareRef={shareRef.current}
                  deleteRef={deleteRef.current}
                  task={task}
                ></TaskCard>
              ))}
              <div className="chevron-spacing">&nbsp;</div>
              <div class="chevron-height-right">
                <div class="chevron-right" onClick={() => scroll(70)}>
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const LastProjects = (props) => {
  const [data, setData] = useState(false);
  const editRef = useRef(null);
  const deleteRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/platform/projects/getlastsforuser");

      setData(result.data.projects);
    };

    fetchData();
  }, []);

  return (
    <div className="mt-4">
      <EditProject ref={editRef}></EditProject>
      <DeleteProject ref={deleteRef}></DeleteProject>

      <h3>Last modified projects</h3>
      <div className="mt-4 position-relative">
        {data === false ? (
          <MyLoader />
        ) : data.length == 0 ? (
          <div className="text-center">
            <h4>You don't have any projects.</h4>
          </div>
        ) : (
          <div>
            <div className="gradient-right"></div>
            <div className="gradient-left"></div>
            <div className="container-scroll">
              {data.map((project) => (
                <ProjectCard
                  key={project._id}
                  token={props.token}
                  history={props.history}
                  editRef={editRef.current}
                  deleteRef={deleteRef.current}
                  project={project}
                ></ProjectCard>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MyLoader = (props) => (
  <ContentLoader
    width={1500}
    height={400}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="300" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="600" y="0" rx="2" ry="2" width="250" height="250" />
    <rect x="900" y="0" rx="2" ry="2" width="250" height="250" />
  </ContentLoader>
);

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  access: state.auth.user.access,
});

export default connect(mapStateToProps)(Dashboard);
