import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
} from "reactstrap";
import industries from "../../../variables/industries.json";
import geographies from "../../../variables/geographies.json";
import channels from "../../../variables/channels.json";
import types from "../../../variables/types.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBlockPresence } from "./report";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
class VcoTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    return (
      <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <div className="mt-4 px-4">
              <CheckBlockPresence analysis={task.analysis} block="vco">
                <Row>
                  <Col xs="4 fade-in">
                  <b>Original</b>
                  <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <LazyLoadImage
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className="card-img-results"
                          src={`/platform/attachments/serveinput?id=${task._id}&token=${token}`}
                        />
                      ) : (
                        <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoplay
                          src={`/platform/attachments/serveinput?id=${task._id}&token=${token}`}
                        ></video>
                      )}
                      </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Visual Attention Heatmap</b>
                    <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <LazyLoadImage
                          placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          effect="blur"
                          src={`/platform/attachments/serveattachment?name=heatmap&id=${task._id}&token=${token}`}
                        />
                      ) : (
                        <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoplay
                          src={`/platform/attachments/serveattachment?name=heatmap&id=${task._id}&token=${token}`}
                        ></video>
                      )}
                    </Card>
                  </Col>
                  <Col xs="4 fade-in">
                    <b>Visual Attention Opacity map</b>
                    <Card>
                      {task.media === "image" || task.media === "dom" ? (
                        <LazyLoadImage
                          placeholderSrc="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          effect="blur"
                          src={`/platform/attachments/serveattachment?name=opacity&id=${task._id}&token=${token}`}
                        />
                      ) : (
                        <video
                          controls
                          replay
                          className="video-result"
                          controlsList="nodownload"
                          autoplay
                          src={`/platform/attachments/serveattachment?name=opacity&id=${task._id}&token=${token}`}
                        ></video>
                      )}
                    </Card>
                  </Col>
                </Row>
              </CheckBlockPresence>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default VcoTab;
