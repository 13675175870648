import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminLayout from "../admin/AdminLayout";
import Sidebar from "../sidebar/Sidebar";
import Header from "../navbars/Header";
import Dashboard from "../home/Dashboard";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import TeamRoutes from "../team/Routes";
import TaskRoutes from "../task/Routes";
import ProjectRoutes from "../project/Routes";
import AdminRoute from "../private-route/AdminRoute";
import axios from "axios";
import { setResourceToken } from "../../actions/resourceActions";
import jwt_decode from "jwt-decode";
import View from "../project/View";
import Profile from "../user/Profile";
import Feedback from "../home/Feedback";


const Internal = (props) => {
  const location = useLocation();



  React.useEffect(() => {
    const { dispatch } = props;
    var update = false;

    if (!props.resource_token.resource_token) {
      update = true;
    } else {
      var decoded = jwt_decode(props.resource_token.resource_token);
      if (decoded.exp < Date.now() / 1000) {
        update = true;
      }
    }

    if (update) {
      axios
        .get("/platform/users/updatetoken")
        .then((res) => {
          var decoded = jwt_decode(res.data.token);
          if (decoded.require_logout) {
            window.location.href = "/logout";
          } else {
            dispatch(setResourceToken(res.data.token));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location]);

  return (
    <div>
           <Feedback></Feedback>
       
      <div className="wrapper">
        <Sidebar location={props.location}></Sidebar>

        <div className="main-panel">
          <Header {...props} />
     
          <Switch>
            <AdminRoute path="/admin" component={AdminLayout} />
            <Route path="/teams" component={TeamRoutes} />
            <Route path="/projects" component={ProjectRoutes} />
            <Route path="/tasks" component={TaskRoutes} />
            <Route exact path="/public" component={(props) => <View {...props} public={true} />} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/home" component={Dashboard} />
            <Route render={() => <Redirect to="/404" />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

Internal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  resource_token: state.resource_token,
});

export default connect(mapStateToProps)(Internal);
