import React, { useState } from "react";

import axios from "axios";
import { Row, Col, Card, CardBody, Badge, Button, Progress } from "reactstrap";
import AddMemberModal from "./AddMemberModal";
import DeleteTeam from "./DeleteTeam";
import BlockTeam from "./BlockTeam";
import CreateKey from "./CreateKey";
import Edit from "./Edit";
import EditKey from "./EditKey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import moment from "moment";

import { connect } from "react-redux";
import { setPageTitle } from "../../../actions/titleActions";
import Logs from "../../log/Log";
import { Helmet } from "react-helmet";

class ViewTeam extends React.Component {
  constructor(props) {
    super(props);

    this.team = this.props.match.params.id;
    this.state = {
      team: false,
    };

    this.members_col = [
      {
        name: "Name",
        selector: (row) => {
          if (row.user) {
            return row.user.full_name;
          } else {
            return row.email;
          }
        },
        cell: (row) => {
          if (row.user) {
            return (
              <a href={`/admin/users/${row.user._id}`}>
                {row.user.full_name}
              </a>
            );
          } else {
            return row.email;
          }
        },

        sortable: true,
        center: true,
      },
      {
        name: "Job title",
        selector: (row) => {
          if (row.user) {
            return row.user.job_title;
          }
        },
        sortable: true,
        center: true,
      },
      {
        name: "Team leader",
        selector: (row) => {
          var leader = false;
          var type = "";
          var value = "";

          if (row.user) {
            type = "team";
            value = row.user._id;
          } else {
            type = "invite";
            value = row.email;
          }
          return (
            <label class="switch">
              <input
                onChange={() => this.changeLeader(type, value)}
                checked={row.leader}
                type="checkbox"
              />
              <span class="slider round"></span>
            </label>
          );
        },
        sortable: false,
        center: true,
      },

      {
        name: "Remove member",
        selector: (row) => {
          var leader = "";
          var type = "";
          var value = "";
          if (row.user) {
            type = "team";
            value = row.user._id;
          } else {
            type = "invite";
            value = row.email;
          }
          return (
            <FontAwesomeIcon
              className="clickable"
              onClick={() => this.removeMember(type, value)}
              icon="times"
            ></FontAwesomeIcon>
          );
        },
        sortable: false,
        center: true,
      },
    ];

    this.projects_col = [
      {
        name: "Name",
        selector: "name",
        cell: (row) => <a href={`/projects/${row._id}`}>{row.name}</a>,
        sortable: true,
        center: true,
      },
      {
        name: "Last activity",
        selector: (row) => moment(row.modified).unix(),
        cell: (row) => moment(row.modified).format("lll"),
        sortable: true,
        center: true,
      },
      {
        name: "Tasks",
        selector: (row) => row.image_cnt + row.video_cnt,
        sortable: false,
        center: true,
      },
    ];

    this.api_col = [
      {
        name: "Key",
        selector: "key",
        sortable: true,
        center: true,
      },

      {
        name: "Quota",
        selector: (row) => {
          if (row.quota === -1) {
            return "Unlimited";
          } else {
            return row.quota;
          }
        },
        sortable: false,
        center: true,
      },

      {
        name: "Access",
        selector: (row) => {
          var colors = {
            adobe: "secondary",
            api: "info",
            chrome: "primary",
          };
          return row.access.map((el) => {
            return (
              <Badge className="mr-1" color={colors[el]}>
                {el.toUpperCase()}
              </Badge>
            );
          });
        },

        sortable: false,
        center: true,
      },
      {
        name: "Edit",
        selector: (row) => {
          return (
            <FontAwesomeIcon
              className="clickable"
              onClick={() => this.editKey.toggle(row)}
              icon="edit"
              title="Edit key"
            ></FontAwesomeIcon>
          );
        },
        sortable: false,
        center: true,
      },
    ];
  }

  changeLeader(type, value) {
    axios
      .post("/platform/admin/teams/toggleleader", {
        id: this.team,
        type: type,
        value: value,
      })
      .then((res) => {
        axios
          .get("/platform/admin/teams/get", { params: { id: this.team } })
          .then((res) => {
            this.setState({ team: res.data });
          })
          .catch((err) => {
            console.log(err);
            // this.props.history.push("/platform/admin/teams");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  removeMember(type, value) {
    axios
      .post("/platform/teams/removemember", {
        id: this.team,
        type: type,
        value: value,
      })
      .then((res) => {
        axios
          .get("/platform/admin/teams/get", { params: { id: this.team } })
          .then((res) => {
            this.setState({ team: res.data });
          })
          .catch((err) => {
            console.log(err);
            // this.props.history.push("/platform/admin/teams");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    const { dispatch } = this.props;

    axios
      .get("/platform/admin/teams/get", { params: { id: this.team } })
      .then((res) => {
        this.setState({ team: res.data });
        dispatch(setPageTitle(res.data.name, "users"));
      })
      .catch((err) => {
        console.log(err);
        // this.props.history.push("/platform/admin/teams");
      });
  }

  render() {
    return (
      <div class="content">
        {this.state.team ? (
          <Helmet>
            <title>{`CRISP - ${this.state.team.name}`}</title>
          </Helmet>
        ) : (
          <b></b>
        )}

        {!this.state.team ? (
          <span></span>
        ) : (
          <div>
            <AddMemberModal
              team={this.team}
              ref={(modal) => {
                this.addModal = modal;
              }}
            ></AddMemberModal>

            <BlockTeam
              team={this.team}
              blocked={this.state.team.blocked}
              ref={(modal) => {
                this.blockTeam = modal;
              }}
            ></BlockTeam>

            <DeleteTeam
              team={this.team}
              ref={(modal) => {
                this.deleteTeam = modal;
              }}
            ></DeleteTeam>

            <CreateKey
              team={this.team}
              ref={(modal) => {
                this.addKey = modal;
              }}
            ></CreateKey>

            <EditKey
              ref={(modal) => {
                this.editKey = modal;
              }}
            ></EditKey>

            <Edit
              ref={(modal) => {
                this.edit = modal;
              }}
              team={this.state.team}
            ></Edit>
            <FontAwesomeIcon
              icon="arrow-left"
              className="prev clickable"
              onClick={() => this.props.history.push("/admin/teams")}
            ></FontAwesomeIcon>
            <Row>
              <Col xs="5">
                <Card>
                  <CardBody>
                    <h3>
                      {this.state.team.name}{" "}
                      <FontAwesomeIcon
                        icon="edit"
                        className="clickable pull-right"
                        onClick={() => this.edit.toggle()}
                      ></FontAwesomeIcon>
                    </h3>

                    <p className="block-info">
                      {this.state.team.description} <br /> <br />
                      <span className="title-info">Last activity: </span>
                      {moment(this.state.team.modified).format("lll")} <br />
                      <span className="title-info">WBS: </span>
                      {this.state.team.wbs} <br />
                      <span className="title-info">Max members: </span>
                      {this.state.team.max_members} <br />
                      <span className="title-info"> Quotas:</span>
                    </p>

                    <div>
                      <div className="w-100">
                        <span className="pull-left">Images</span>{" "}
                        <span className="pull-right">
                          {this.state.team.current_quota_image}/
                          {this.state.team.quota_image}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={ this.state.team.current_quota_image /
                          this.state.team.quota_image*100 > 80 ? "danger" :  (this.state.team.current_quota_image /
                          this.state.team.quota_image*100 > 60 ? "warning" : 'info')}
                        value={
                          this.state.team.current_quota_image /
                          this.state.team.quota_image*100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">Videos</span>{" "}
                        <span className="pull-right">
                          {this.state.team.current_quota_video}/
                          {this.state.team.quota_video}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={ this.state.team.current_quota_video /
                          this.state.team.quota_video*100 > 80 ? "danger" :  (this.state.team.current_quota_video /
                          this.state.team.quota_video*100 > 60 ? "warning" : 'info')}
                        value={
                          this.state.team.current_quota_video /
                          this.state.team.quota_video*100
                        }
                      />
                    </div>
                    <div className="mt-3">
                      <div className="w-100">
                        <span className="pull-left">HTML</span>{" "}
                        <span className="pull-right">
                          {this.state.team.current_quota_dom}/
                          {this.state.team.quota_dom}
                        </span>
                      </div>
                      <Progress
                        className="w-100"
                        color={
                          (this.state.team.current_quota_dom /
                            this.state.team.quota_dom) *
                            100 >
                          80
                            ? "danger"
                            : (this.state.team.current_quota_dom /
                                this.state.team.quota_dom) *
                                100 >
                              60
                            ? "warning"
                            : "info"
                        }
                        value={
                          (this.state.team.current_quota_dom /
                            this.state.team.quota_dom) *
                          100
                        }
                      />
                    </div>
                    <div className="mt-3 text-right">
                      <Button
                        color={"danger"}
                        onClick={() => this.deleteTeam.toggle()}
                      >
                        Delete
                      </Button>
                      <Button
                        color={
                          this.state.team.blocked == true ? "success" : "danger"
                        }
                        onClick={() => this.blockTeam.toggle()}
                      >
                        {this.state.team.blocked ? "Unblock" : "Block"}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="7">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Statistics</h4>
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Members</h4>
                    <Button
                      color="primary"
                      onClick={() => this.addModal.toggle()}
                      className="m-0 pull-right"
                    >
                      Add member
                    </Button>
                    <DataTable
                      pagination={true}
                      columns={this.members_col}
                      data={this.state.team.members.concat(
                        this.state.team.invitations
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Projects</h4>
                    <DataTable
                      pagination={true}
                      columns={this.projects_col}
                      data={this.state.team.projects}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Keys</h4>
                    <Button
                      color="primary"
                      onClick={() => this.addKey.toggle()}
                      className="m-0 pull-right"
                    >
                      Create key
                    </Button>
                    <DataTable
                      pagination={true}
                      columns={this.api_col}
                      data={this.state.team.keys}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Col xs="6">
                <Card>
                  <CardBody>
                    <h4 className="m-0">Logs</h4>
                    <Logs logs={this.state.team.logs}></Logs>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(ViewTeam);
