import React, { Component } from "react";
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

class ImageLabels extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Labels
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="9" className="py-2 text-title dark-background">
                NAME
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                SCORE
              </Col>
            </Row>
          </Col>
          <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col>
        </Row>
        <Row className="border-row">
          <Col xs="4" className="py-3"></Col>
          <Col xs="6" className="py-3 dark-background">
            {this.props.data.results?.result?.results?.map(function (label) {
              return (
                <Row>
                  <Col xs="9">{label.label}</Col>
                  <Col xs="3">{Math.round(label.score * 100)}%</Col>
                </Row>
              );
            })}
            {this.props.data.results?.results?.map(function (label) {
              return (
                <Row>
                  <Col xs="9">{label.label}</Col>
                  <Col xs="3">{Math.round(label.score * 100)}%</Col>
                </Row>
              );
            })}
          </Col>
          <Col xs="2" className="py-3">
            <div>{this.props.data.results?.recommendation?.results?.reco_pos}</div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageLabels;
