import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import { Cube } from "../tabs/report";
class DomCTA extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score = 0;
    let total_score = 0;
    if (this.props.data.results?.CTA_bold?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.CTA_bold?.results?.correct_cta_bold) {
        score = score + 1;
      }
    }
    if (this.props.data.results?.CTA_number?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.CTA_number?.results?.correct_cta_number) {
        score = score + 1;
      }
    }
    if (this.props.data.results?.CTA_textual?.results) {
      total_score = total_score + 1;
      if (this.props.data.results.CTA_textual?.results?.correct_cta_char) {
        score = score + 1;
      }
    }
    if (this.props.data.results?.CTA_duplicate?.results) {
      total_score = total_score + 1;
      if (
        this.props.data.results.CTA_duplicate?.results?.correct_cta_duplicate
      ) {
        score = score + 1;
      }
    }

    let scorefinal = (score / total_score) * 100;

    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        {!this.props.data.results.CTA_presence?.results?.cta_presence ? (
          <>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>
            <Row className="border-row">
              <Col className=" py-3" xs="4">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.CTA_presence?.results
                          ?.cta_presence
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">CTA</Col>
                </Row>
              </Col>
              <Col className="py-3 dark-background" xs="3">
                No CTA detected
              </Col>
              <Col className="py-3 dark-background" xs="3"></Col>
              <Col className="py-3 " xs="2">
                {this.props.data.results.CTA_presence?.results?.reco_neg}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="py-2" xs="4"></Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RESULT
              </Col>
              <Col className="py-2 text-title dark-background" xs="3">
                RECOMMENDED
              </Col>
              <Col className="py-2 text-title" xs="2">
                DETAILS
              </Col>
            </Row>

            {this.props.data.results?.CTA_bold?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results?.CTA_bold?.results
                            ?.correct_cta_bold
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA boldness</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  Not all CTA are bold
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  CTA should be bold
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results?.CTA_bold?.results
                    ?.correct_cta_bold ? (
                    <div>
                      {
                        this.props.data.results?.CTA_bold?.results
                          ?.reco_pos_bold
                      }
                    </div>
                  ) : (
                    <div>
                      {
                        this.props.data.results?.CTA_bold?.results
                          ?.reco_neg_bold
                      }
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.CTA_number?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_number.results
                            .correct_cta_number
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA Number</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.CTA_number.results.number_cta} CTA
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min number of CTA:{" "}
                      {
                        this.props.data.results.CTA_number.results
                          .min_expected_number
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max number of CTA:{" "}
                      {
                        this.props.data.results.CTA_number.results
                          .max_expected_number
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results.CTA_number.results
                    .correct_cta_number ? (
                    <div>
                      {this.props.data.results.CTA_number.results.reco_pos_min}{" "}
                      {this.props.data.results.CTA_number.results.reco_pos_max}{" "}
                    </div>
                  ) : (
                    <div>
                      {this.props.data.results.CTA_number.results.reco_neg_min}{" "}
                      {this.props.data.results.CTA_number.results.reco_neg_max}
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {this.props.data.results?.CTA_textual?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_textual.results
                            .correct_cta_char
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Textual CTA</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.CTA_textual?.results.too_long_cta ? (
                    <>
                      <Row>
                        <Col>
                          Too long CTA:{" "}
                          {Math.round(
                            this.props.data.results.CTA_textual.results
                              ?.too_long_cta.length
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          Too short CTA:{" "}
                          {Math.round(
                            this.props.data.results.CTA_textual.results
                              ?.too_short_cta.length
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>
                      Min number of characters :{" "}
                      {
                        this.props.data.results.CTA_textual.results
                          .min_expected_char
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Max number of characters:{" "}
                      {
                        this.props.data.results.CTA_textual.results
                          .max_expected_char
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results.CTA_textual.results
                    .correct_cta_char ? (
                    <div>
                      {this.props.data.results.CTA_textual.results.reco_pos_min}{" "}
                      {this.props.data.results.CTA_textual.results.reco_pos_max}{" "}
                    </div>
                  ) : (
                    <div>
                      {this.props.data.results.CTA_textual.results.reco_neg_min}{" "}
                      {this.props.data.results.CTA_textual.results.reco_neg_max}
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results?.CTA_duplicate?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_duplicate.results
                            .correct_cta_duplicate
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Duplicates</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {
                    this.props.data.results.CTA_duplicate.results
                      .number_duplicate
                  }{" "}
                  duplicated CTA
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  CTA should be duplicated
                </Col>
                <Col xs="2" className="py-3">
                  {this.props.data.results.CTA_duplicate.results
                    .correct_cta_duplicate ? (
                    <div>
                      {
                        this.props.data.results.CTA_duplicate.results
                          .reco_pos_duplicate
                      }
                    </div>
                  ) : (
                    <div>
                      {
                        this.props.data.results.CTA_duplicate.results
                          .reco_neg_duplicate
                      }
                    </div>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results.CTA_fold?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_fold.results
                            .correct_cta_fold
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">CTA Above the fold</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.CTA_fold?.results.number_cta_fold ? (
                    <>
                      <Row>
                        <Col>
                          Number of CTA above the fold:{" "}
                          {Math.round(
                            this.props.data.results.CTA_fold.results
                              ?.number_cta_fold
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>There should be a CTA above the fold</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}

            {this.props.data.results.CTA_descriptive?.results ? (
              <Row className="border-row">
                <Col xs="4" className="py-3">
                  <Row>
                    <Col xs="2">
                      <Cube
                        color={
                          this.props.data.results.CTA_descriptive.results
                            .correct_cta_descriptive
                        }
                      ></Cube>
                    </Col>
                    <Col xs="10">Descriptive CTA</Col>
                  </Row>
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  {this.props.data.results.CTA_descriptive?.results
                    .number_non_descriptive ? (
                    <>
                      <Row>
                        <Col>
                          Number of non descriptive CTA:{" "}
                          {Math.round(
                            this.props.data.results.CTA_descriptive.results
                              ?.number_non_descriptive
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs="3" className="py-3 dark-background">
                  <Row>
                    <Col>CTA should be descriptive</Col>
                  </Row>
                </Col>
                <Col xs="2" className="py-3"></Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }
}

export default DomCTA;
