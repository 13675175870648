import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";

import {Cube} from "../tabs/report"
class EmailAccessibility extends React.Component {
  constructor(props) {
    super(props);
  }

  generateScore() {}

  render() {
    return (
      <>
        <Row>
          <Col className="py-2" xs="2">
            COLOR
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.color_contrast.results
                  .correct_color_contrast
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Correct color contrast between the text and the background (4.5:1
            for normal text, 3:1 for large text).
          </Col>
        </Row>

        <Row>
          <Col className="py-2" xs="2">
            IMAGES
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.alt_text_image.results
                  .presence_alt_text_image
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Images include alt text
          </Col>
        </Row>

        <Row>
          <Col className="py-2" xs="2">
            TEXT
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.copy_two_lines.results.correct_copy_body
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            All text copy longer than two lines is left aligned.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.heading_order.results.correct_headings
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Has accurate heading tags.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.text_image_ratio.results.correct_ratio
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Has a 60/40 text to image ratio.
          </Col>
        </Row>

        <Row>
          <Col className="py-2" xs="2">
            VIDEO/GIF
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.flashing_content.results
                  .correct_flashing
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Flashing content does not flash more than 3 times per second.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.closed_captioning.results
                  .closed_captioning
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Presence of closed caption on the video in the email
          </Col>
        </Row>

        <Row>
          <Col className="py-2" xs="2">
            LINKS
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.cta_label.results.correct_cta_label
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Does not include the words “Link” or “Button” in CTA buttons, anchor
            text, or alt text.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.link_style.results.correct_link_style
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Inline hyperlinked text is underlined or bold.
          </Col>
        </Row>
        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.link_title.results.correct_link_title
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Links do not have titles.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.purpose_link.results
                  .correct_purpose_link
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            The purpose of hyperlinked text is clear. Avoid “Learn More”, “Get
            Started”, "Click Here", etc.
          </Col>
        </Row>

        <Row>
          <Col className="py-2" xs="2">
            LAYOUT
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.font_size.results.correct_font_size
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Minimum font size is 14px in the email body.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.line_spacing.results
                  .correct_line_spacing
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            For text smaller than 28px, line spacing should be between 1.5x and
            2x. For text 28px or larger, line spacing should be at least 1.2x.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.touch_target.results
                  .correct_size_touch_target
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Touch targets are a minimum of 48x48px, and spaced at least 8px
            apart
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.table_role.results.correct_table_role
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Tables that are not meant to present data are set to
            role="Presentation".
          </Col>
        </Row>

        <Row>
          <Col className="py-2" xs="2">
            SETUP
          </Col>
          <Col className="py-2 text-title dark-background" xs="10">
            RESULT
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={this.props.data.results.encoding.results.correct_encoding}
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            Characters are encoded.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.html_language.results
                  .correct_html_language
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            The HTML language attribute is set.
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="2" className="py-3">
            <Cube
              color={
                this.props.data.results.title_presence.results
                  .correct_title_presence
              }
            ></Cube>
          </Col>
          <Col xs="10" className="py-3 dark-background">
            The email has a descriptive &lt; title &gt;
          </Col>
        </Row>
      </>
    );
  }
}


export default EmailAccessibility;
