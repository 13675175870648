import React, { Component } from "react";
import { Row, Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

class ImageLogos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="border-row mt-4 no-top-border">
          <Col className="py-2 text-title" xs="4">
            Logos
          </Col>
          <Col xs="6">
            <Row>
              <Col xs="6" className="py-2 text-title dark-background">
                NAME
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                SIZE
              </Col>
              <Col xs="3" className="py-2 text-title dark-background">
                VISIBILITY
              </Col>
            </Row>
          </Col>
          <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col>
        </Row>
        <Row className="border-row">
          <Col xs="4" className="py-3">
            {this.props.data.results.logo_size.results.length > 0 ? (
              <CardImg
                top
                width="100%"
                className="card-img-results"
                src={`/platform/attachments/serveattachment?name=imagelogo&id=${this.props.id}&token=${this.props.token}`}
                alt="Card image cap"
              />
            ) : (
              <div>No Logo detected</div>
            )}
          </Col>
          <Col xs="6" className="py-3 dark-background">
            {this.props.data.results.logo_size.results.map(function (logo) {
              return (
                <Row>
                  <Col xs="6">{logo.logo}</Col>
                  <Col xs="3">{Math.round(logo.sizePercent)}%</Col>
                  <Col xs="3">{Math.round(logo.visibility)}</Col>
                </Row>
              );
            })}
          </Col>
          <Col xs="2" className="py-3">
          {this.props.data.results?.recommendation?.results
                                ?.reco_pos} 
          </Col>
        </Row>
      </>
    );
  }
}

export default ImageLogos;
