import React, { useState } from "react";
import axios from "axios";
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setPageTitle } from "../../actions/titleActions";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import industries from "../../variables/industries.json";
import geographies from "../../variables/geographies.json";
import channels from "../../variables/channels.json";
import types from "../../variables/types.json";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import View from "../admin/teams/View";

import ErrorJSON from "../utils/Error";
import Edit from "./Edit";
import VcoTab from "./tabs/vco";
import MemorabilityTab from "./tabs/memorability";
import { CheckBlockPresence, ReportTab } from "./tabs/report";
import AccessibilityTab from "./tabs/accessibility";
import LighthouseTab from "./tabs/lighthouse";
import BBVATab from "./tabs/bbva";

class ViewTask extends React.Component {
  constructor(props) {
    super(props);
    //this.onResolved = this.onResolved.bind(this);
    this.task = this.props.match.params.id;

    this.state = {
      task: false,
      activeTab: "1",
    };

    if (this.state.task?.tasks?.includes("accessibility")) {
      this.state.activeTab = "4";
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    var task = this.task;
    axios
      .get("/platform/tasks/gettask", {
        params: {
          id: task,
        },
      })
      .then((res) => {
        var type = false;
        if(res.data.type) {
          type = types.find((type) => type.name == res.data.type).pretty_name;

        } 

        dispatch(
          setPageTitle(
            res.data.project.name +
              (type ? (" - " + type) : ("")) +
              " - " +
              moment(res.data.creation).format("YYYY-MM-DD"),
            "file-alt"
          )
        );

        this.setState({
          task: {
            ...res.data,
            crop_name:
              res.data.label.length > 35
                ? res.data.label.substring(0, 35) + "..."
                : res.data.label,
          },
        });

        if (res.data.status !== "finished") {
          this.props.history.push("/projects/" + res.data.project._id);
        }

        if (this.state.task?.tasks?.includes("bbva")) {
          this.state.activeTab = "6";
        } 
        // console.log("task", this.state.task);
        else if (this.state.task?.tasks?.includes("accessibility")) {
          this.state.activeTab = "4";
        } else if (
          !this.state.task?.tasks?.includes("mcu") &&
          this.state.task?.tasks?.includes("accessibility")
        ) {
          this.state.activeTab = "3";
        }
      })
      .catch((err) => {
        this.props.history.push("/home");
      });
  }

  render() {
    var task = this.state.task;
    var token = this.props.resource_token.resource_token;

    return (
      <div className="content">
        <Edit
          ref={(modal) => {
            this.editRef = modal;
          }}
          history={this.props.history}
          id={this.state.task._id}
          access={this.props.access}
        ></Edit>

        <Helmet>
          <title>{`CRISP - ${this.state.task.label}`}</title>
        </Helmet>
        {this.state.errors ? (
          <ErrorJSON json={this.state.errors}></ErrorJSON>
        ) : (
          <span></span>
        )}
        <div className="px-3">
          {!task.analysis ? (
            <span></span>
          ) : (
            <>
              <Row className="my-5 justify-content-md-right">
                <Col xs="6">
                  <Row>
                    <Col xs="10" className="offset-1">
                      <Row className="">
                        <span className="  top-text">
                          <a className="serious-link" href={`/teams/${task.project.team._id}`}>
                            {`${task.project.team.name}`}
                          </a>
                          <span className="bold chev">{"  >  "}</span>
                          <a className="serious-link" href={`/projects/${task.project._id}`}>
                            {`${task.project.name}`}
                          </a>
                          {"  "}
                          <FontAwesomeIcon
                            onClick={() =>
                              this.editRef.toggle(task, task.project?.team._id)
                            }
                            className="icon-card clickable ml-4"
                            icon="edit"
                            title="Edit"
                          />
                        </span>
                      </Row>
                      <Row className=" top-text">{this.state.task.label}</Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs="12">
                      {this.state.task.channel == "" ? (
                        <></>
                      ) : (
                        <Row>
                          <Col
                            className="justify-content-end text-right"
                            xs="5"
                          >
                            <b>Channel:</b>
                          </Col>
                          <Col xs="6">
                            {this.state.task.channel != ""
                              ? channels.find(
                                  (channel) =>
                                    channel.name == this.state.task.channel
                                ).pretty_name
                              : "Not specified"}
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Type:</b>
                        </Col>
                        <Col xs="6">
                          {this.state.task.type != ""
                            ? types.find(
                                (type) => type.name == this.state.task.type
                              ).pretty_name
                            : "Not specified"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Industry:</b>
                        </Col>
                        <Col xs="6">
                          {this.state.task.industry != ""
                            ? industries.find(
                                (industry) =>
                                  industry.name == this.state.task.industry
                              ).pretty_name
                            : "Not specified"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Geography:</b>
                        </Col>
                        <Col xs="6">
                          {this.state.task.geography != ""
                            ? geographies.find(
                                (geography) =>
                                  geography.name == this.state.task.geography
                              ).pretty_name
                            : "Not specified"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="justify-content-end text-right" xs="5">
                          <b>Created:</b>
                        </Col>
                        <Col xs="6">{moment(task.creation).format("LL")}</Col>
                      </Row>
                      {task.method == "url" ? (
                        <>
                          <Row>
                            <Col
                              className="justify-content-end text-right"
                              xs="5"
                            >
                              <b>Requested URL:</b>
                            </Col>
                            <Col xs="6">
                              <a
                                target="_blank"
                                className="quiet-a"
                                href={task.url}
                              >
                                {task.url.split("/")[2]}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="justify-content-end text-right"
                              xs="5"
                            >
                              <b>Analyzed URL:</b>
                            </Col>
                            <Col xs="6">
                              <a
                                target="_blank"
                                className="quiet-a"
                                href={task.url}
                              >
                                {task.url}
                              </a>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs="4" className="justify-content-center text-center">
                  {" "}
                  {task.media === "image" || task.media === "dom" ? (
                    <>
                      {task.method == "url" ? (
                        <div className="rendering-container">
                          <img
                            className="rendering-device"
                            src={`/img/desktop.png`}
                          ></img>
                          <div
                            className={`rendering-content-container desktop`}
                          >
                            <img></img>

                            <LazyLoadImage
                              placeholderSrc={
                                "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                              }
                              effect="blur"
                              className="rendering-content"
                              src={`/platform/attachments/serveinput?id=${task._id}&token=${token}`}
                            />
                          </div>
                        </div>
                      ) : (
                        <LazyLoadImage
                          placeholderSrc={
                            "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                          }
                          effect="blur"
                          className=" max-height  justify-content-center text-center"
                          src={`/platform/attachments/serveinput?id=${task._id}&token=${token}`}
                        />
                      )}
                    </>
                  ) : (
                    <video
                      controls
                      replay
                      className="video-result"
                      controlsList="nodownload"
                      autoplay
                      src={`/platform/attachments/serveinput?id=${task._id}&token=${token}`}
                    ></video>
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
        <div className="text-dark-blue">
          <Nav tabs>
          {!this.state.task?.tasks?.includes("bbva") ? (                   
            <NavItem className="col tabs-design">
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Predictive Design
                {!this.props.access.includes("mcu") ? (
                  <FontAwesomeIcon className="ml-2" icon="lock" />
                ) : (
                  <></>
                )}
              </NavLink>
            </NavItem>
            ) : (
              <></>
            )}

            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="dom_lighthouse"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Page Experience Report
                </NavLink>
              </NavItem>
            </CheckBlockPresence>

            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="email_accessibility"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Accessibility Report
                  {!this.props.access.includes("accessibility") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem>
            </CheckBlockPresence>
            <CheckBlockPresence analysis={this.state.task.analysis} block="vco">
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  Visual Attention Prediction
                </NavLink>
              </NavItem>
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="image_memorability"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggle("5");
                  }}
                >
                  Memorability Prediction
                  {!this.props.access.includes("memorability") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem>
            </CheckBlockPresence>
            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="dom_form"
            >
              <NavItem className="col tabs-design">
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "6",
                  })}
                  onClick={() => {
                    this.toggle("6");
                  }}
                >
                  BBVA Report
                  {!this.props.access.includes("mcu") ? (
                    <FontAwesomeIcon className="ml-2" icon="lock" />
                  ) : (
                    <></>
                  )}
                </NavLink>
              </NavItem>
            </CheckBlockPresence>

          </Nav>
          <TabContent activeTab={this.state.activeTab}>
          {!this.state.task?.tasks?.includes("bbva") ? (          
            <TabPane tabId="1">
              <ReportTab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
                history={this.props.history}
                access={this.props.access}
              ></ReportTab>
            </TabPane>
              ) : (
                <></>
              )}

            <CheckBlockPresence
              analysis={this.state.task.analysis}
              block="dom_lighthouse"
            >
              <TabPane tabId="2">
                <LighthouseTab
                  task={this.state.task}
                  token={token}
                ></LighthouseTab>
              </TabPane>
            </CheckBlockPresence>

            <TabPane tabId="3">
              <AccessibilityTab
                task={this.state.task}
                editRef={this.editRef}
                token={token}
                access={this.props.access}
              ></AccessibilityTab>
            </TabPane>

            <TabPane tabId="4">
              <VcoTab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
              ></VcoTab>
            </TabPane>

            <TabPane tabId="5">
              <MemorabilityTab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
                access={this.props.access}
              ></MemorabilityTab>
            </TabPane>

            <TabPane tabId="6">
              <BBVATab
                editRef={this.editRef}
                task={this.state.task}
                token={token}
                access={this.props.access}
              ></BBVATab>
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

// const Example = (props) => {};

const mapStateToProps = (state) => ({
  resource_token: state.resource_token,
  access: state.auth.user.access,
});

export default connect(mapStateToProps)(ViewTask);
