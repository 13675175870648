import { SET_CURRENT_PAGE_TITLE } from "./types";

// Set logged in user
export const setPageTitle = (title, icon) => {
  return {
    type: SET_CURRENT_PAGE_TITLE,
    title: title,
    icon: icon,
  };
};
