import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";

class ImageCopywriting extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let score =
      1 -
      this.props.data.results.text_too_long.results.averageTooLong +
      (1 - this.props.data.results.text_too_short.results.averageTooShort);

    let scorefinal = (score / 2) * 100;

    this.props.generateScore(scorefinal);
  }

  render() {
    return !this.props.data.results.text_presence?.results?.textPresence ? (
      <>
        <Row>
          <div className="category-text">MESSAGE</div>
        </Row>
        <div>No text detected</div>
      </>
    ) : (
      <>
        <Row>
          <div className="category-text">MESSAGE</div>
        </Row>
        <Row>
          <Col className="py-2" xs="4"></Col>
          <Col className="py-2 text-title dark-background" xs="3">
            RESULT
          </Col>
          <Col className="py-2 text-title dark-background" xs="3">
            RECOMMENDED
          </Col>
          <Col className="py-2 text-title" xs="2">
            DETAILS
          </Col>
        </Row>

        {/* <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={Math.abs(
                    this.props.data.results.text_language.results
                      .averageCorrectLanguage *
                      100 -
                      100
                  )}
                ></Cube>
              </Col>
              <Col xs="10">
                {this.props.data.results.text_language.results
                  .averageCorrectLanguage *
                  100 -
                  100 ==
                0 ? (
                  <div>Correct language detected</div>
                ) : (
                  <div>Language mismatch</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="3" className="py-3 dark-background">
            Expected Language
          </Col>
          <Col xs="3" className="py-3 dark-background">
            {this.props.data.results.text_language.results.languages}
          </Col>
        <Col xs="2" className="py-3">
            Details
          </Col> 
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.text_mistake.results
                      .averageMistakes * 100
                  }
                ></Cube>
              </Col>
              <Col xs="10">
                {this.props.data.results.text_mistake.results.averageMistakes *
                  100 ==
                0 ? (
                  <div>No spelling mistake</div>
                ) : (
                  <div>Spelling mistakes detected</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="3" className="py-3 dark-background">
            Average Number of Mistakes
          </Col>
          <Col xs="3" className="py-3 dark-background">
            {Math.round(
              this.props.data.results.text_mistake.results.averageMistakes * 100
            )}{" "}
            %
          </Col>
          <Col xs="2" className="py-3">
            Details
          </Col> 
        </Row> */}

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.text_too_short.results
                      .averageTooShort * 100
                  }
                ></Cube>
              </Col>
              <Col xs="10">Wordiness</Col>
            </Row>
          </Col>
          <Col xs="3" className="py-3 dark-background">
            {Math.round(
              this.props.data.results.text_too_short.results.averageTooShort *
                100
            )}
            % Text too short
          </Col>
          <Col xs="3" className="py-3 dark-background">
            Minimum Characters:{" "}
            {this.props.data.results.text_too_short.results.min_length_variable}
          </Col>
          <Col xs="2" className="py-3">
            {this.props.data.results.text_too_short.results.averageTooShort *
              100 ==
            0 ? (
              <div>
                {
                  this.props.data.results.text_too_short.results
                    .reco_pos_min_length
                }
              </div>
            ) : (
              <div>
                {
                  this.props.data.results.text_too_short.results
                    .reco_neg_min_length
                }
              </div>
            )}
          </Col>
        </Row>

        <Row className="border-row">
          <Col xs="4" className="py-3">
            <Row>
              <Col xs="2">
                <Cube
                  color={
                    this.props.data.results.text_too_long.results
                      .averageTooLong * 100
                  }
                ></Cube>
              </Col>
              <Col xs="10">Conciseness</Col>
            </Row>
          </Col>
          <Col xs="3" className="py-3 dark-background">
            {Math.round(
              this.props.data.results.text_too_long.results.averageTooLong * 100
            )}
            % Text too long
          </Col>
          <Col xs="3" className="py-3 dark-background">
            Maximum Characters:{" "}
            {this.props.data.results.text_too_long.results.max_length}
          </Col>
          <Col xs="2" className="py-3">
            {this.props.data.results.text_too_short.results.averageTooLong *
              100 ==
            0 ? (
              <div>
                {
                  this.props.data.results.text_too_long.results
                    .reco_pos_max_length
                }
              </div>
            ) : (
              <div>
                {
                  this.props.data.results.text_too_long.results
                    .reco_neg_max_length
                }
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const Cube = (props) => {
  return props.color == 0 ? (
    <img src={"/img/IconPassBig.png"} className="icon-score"></img>
  ) : props.color < 50 ? (
    <img src={"/img/IconWarningBig.png"} className="icon-score"></img>
  ) : (
    <img src={"/img/IconFailBig.png"} className="icon-score"></img>
  );
};

export default ImageCopywriting;
