/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.sidebar = React.createRef();
    this.state = {
      teams: [],
    };
  }

  componentDidMount() {

    axios
      .get("/platform/teams/getforcurrentuser")
      .then((res) => {
        console.log(res.data.teams);
        this.setState({ teams: res.data.teams });
        console.log(this.state);
      })
      .catch((err) => {
        console.log(err);
        // this.props.history.push("/admin/teams");
      });

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  isLeader() {

    for( var i = 0; i< this.state.teams.length; i++){
      if(this.state.teams[i].leader){
        return true
      }
    }

    return false
  }


  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a href="/" className="simple-text logo">
            <div className="logo-img">
              <img src={"/img/logocrisp.png"} alt="react-logo" />
            </div>
          </a>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
            <MenuItem
              name="dashboard"
              icon="home"
              location={this.props.location}
              path="/home"
            ></MenuItem>
            {this.state.teams.length === 0? (
              <MenuItem
                name="Public"
                icon="globe-europe"
                location={this.props.location}
                path="/public"
              ></MenuItem>
            ) : (
              <MenuCollapse
                location={this.props.location}
                folder="/teams/"
                name="My teams"
                icon="users"
              >
                <ul className="ul-hide">
                  <MenuItem
                    name="Public"
                    icon="globe-europe"
                    location={this.props.location}
                    path="/public"
                  ></MenuItem>
                  {this.state.teams.map((team) => {
                    if (team.team.public != true) {
                      return (
                        <MenuItem
                          key={team.team.name}
                          name={team.team.name}
                          icon="users"
                          location={this.props.location}
                          path={"/teams/" + team.team._id}
                        ></MenuItem>
                      );
                    }
                  })}
                </ul>
              </MenuCollapse>
            )}
            {this.isLeader() ? (
            <MenuItem
              name="Manage teams"
              icon="shield-alt"
              location={this.props.location}
              path="/teams/manage"
            ></MenuItem>
            ) : (<></>)
    }
          </Nav>

          {this.props.auth.user.admin ? (
            <div>
              <p className="admin-sep">ADMIN</p>
              <AdminMenu location={this.props.location}></AdminMenu>
            </div>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    );
  }
}

const AdminMenu = (props) => {
  return (
    <Nav>
      <MenuItem
        name="dashboard"
        icon="tachometer-alt"
        location={props.location}
        path="/admin/dashboard"
      ></MenuItem>
      <MenuItem
        name="Teams"
        icon="users"
        location={props.location}
        path="/admin/teams"
      ></MenuItem>
      <MenuItem
        name="Users"
        icon="user"
        location={props.location}
        path="/admin/users"
      ></MenuItem>
      <MenuItem
        name="Explorer"
        icon="database"
        location={props.location}
        path="/admin/explorer"
      ></MenuItem>
      <MenuItem
        name="Logs"
        icon="file-alt"
        location={props.location}
        path="/admin/logs"
      ></MenuItem>
      <MenuItem
        name="Feedbacks"
        icon="comments"
        location={props.location}
        path="/admin/feedbacks"
      ></MenuItem>
      <MenuItem
        name="Raws"
        icon="server"
        location={props.location}
        path="/admin/raws"
      ></MenuItem>
      );
    </Nav>
  );
};
const MenuItem = (props) => {
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <li className={activeRoute(props.path)}>
      <NavLink to={props.path} className="nav-link" activeClassName="active">
        <p>
          <FontAwesomeIcon className="mr-4" icon={props.icon} />
          {props.name}
        </p>
      </NavLink>
    </li>
  );
};

const MenuCollapse = (props) => {
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const [isOpen, setIsOpen] = useState(activeRoute(props.folder));

  const toggle = (e) => {
    console.log("togle");
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <li className={isOpen ? "collapse-open" : ""}>
      <NavLink to="" onClick={(e) => toggle(e)} className="nav-link">
        <p>
          <FontAwesomeIcon className="mr-4" icon={props.icon} />
          {props.name}{" "}
          <FontAwesomeIcon
            className={(isOpen ? "up" : "") + " caret-s"}
            icon="caret-down"
          />
        </p>
      </NavLink>
      <Collapse isOpen={isOpen}>{props.children}</Collapse>
    </li>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sidebar);
