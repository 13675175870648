import { Alert } from "reactstrap";
import React from "react";

function ErrorJSON(props) {
  return props.json === false ? (
    <span></span>
  ) : (
    <Alert color="danger">
      {typeof props.json === "string" || props.json instanceof String
        ? props.json
        : Object.keys(props.json).map(function (key) {
            return (
              <span>
                <b>{key}:</b> {props.json[key]}
                <br></br>
              </span>
            );
          })}
    </Alert>
  );
}


export default ErrorJSON;
