import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ViewTask from "./View";

class TaskRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/tasks/:id" component={ViewTask} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    );
  }
}

export default TaskRoutes;
