import React from "react";
import {

    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,

  } from "reactstrap";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubActions = ({ value, onChange }) => (
    <InputGroup className="no-border search-table">
      <Input
        className="input-blue-bg"
        value={value}
        onChange={onChange}
        placeholder="Search..."
      />
      <InputGroupAddon addonType="append">
        <InputGroupText className="input-blue-bg">
          <FontAwesomeIcon icon="search" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );

  export default SubActions