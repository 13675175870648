import React, { Component } from "react";
import { Button } from "reactstrap";

class NotFound extends React.Component {
  componentDidMount() {
    console.log(this.props.auth);
  }

  render() {
    return (
      <div className="error-bg">
        <div className="error-h">
          <h2>404</h2>
          <Button onClick={() => (window.location.href = "/")}>
            Back to home
          </Button>
        </div>
      </div>
    );
  }
}

export default NotFound;
