import React from "react";

import {
  Row,
  Col,
  Badge,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const ProjectCard = (props) => (
  <Col xs="4">
    <Card className="fade-in">
      <a className="cardimg-project-container " onClick={(e) =>{
          props.history.push("/projects/" + props.project._id)
          e.preventDefault();}
        } href={"/projects/" + props.project._id}>

<LazyLoadImage
                  placeholderSrc={
                    "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUAB4DASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAUCBgMEB//EAB4QAAEEAwEBAQAAAAAAAAAAAAIAAQMEERIhMQUT/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECBP/EABkRAQEBAQEBAAAAAAAAAAAAAAEAAiExQf/aAAwDAQACEQMRAD8A7/SnGMcu6Zw/VgdsOTKoxWto9WdQGEiLOzstOM50dlkPtbrNuOUX1JnVbvG36KAm8I9PKXXLeT9UaA8pTvLQqyFzqYhKevqEKCbYLEp4fqU2JC29QhJgv//Z"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/img/loading.gif";
                  }}
                  effect="blur"
                  className="card-img cursorp"
                  src={
                    props.project.last_task === "none"
                      ? "https://www.larcorporel.com/wp-content/uploads/2019/10/placeholder.png"
                      : `/platform/attachments/servethumb?id=${props.project.last_task._id}&token=${props.token}`
                  }
                />


      </a>
      <CardBody>
        <CardTitle className="project-title">{props.project.name}</CardTitle>

        <CardText>{props.project.description}</CardText>
        <Row className="text-center">
          <Col>
            <h5>
              {props.project.image_cnt} <br></br>
              <Badge>IMAGES</Badge>
            </h5>
          </Col>
          <Col>
            <h5>
              {props.project.video_cnt} <br></br>
              <Badge color="danger">VIDEOS</Badge>
            </h5>
          </Col>
          <Col>
            <h5>
              {props.project.dom_cnt} <br></br>
              <Badge color="success">HTML</Badge>
            </h5>
          </Col>
      
        </Row>

        <p>
          <span className="date-card">
            {" "}
            Last activity {moment(props.project.modified).format("lll")}
          </span>
          <span className="pull-right">
            <FontAwesomeIcon
              onClick={() => props.editRef.toggle(props.project)}
              className="icon-card clickable mr-2"
              icon="edit"
              title="Edit project"
            />
            <FontAwesomeIcon
              onClick={() => props.deleteRef.toggle(props.project._id)}
              className="icon-card clickable"
              icon="trash"
              title="Delete project"
            />
          </span>
        </p>
      </CardBody>
    </Card>
  </Col>
);

export default ProjectCard;
