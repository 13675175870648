/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import striptags from "striptags";

import axios from "axios";
import ErrorJSON from "../../utils/Error";
import AddMember from "./AddMember";

class AddMemberModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.team = this.props.team;

    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ errors: false });

    if (this.members.state.members.length === 0) {
      this.setState({ errors: "Please add at least one member the team." });
      return;
    }

    axios
      .post("/platform/teams/addmembers", {
        id: this.team,
        members: this.members.state.members,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Add members</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <AddMember
                ref={(members) => (this.members = members)}
                team={this.team}
              ></AddMember>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default AddMemberModal;
