import React, { Component } from "react";
import {
  Label,
  Card,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  CardBody,
  Alert,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import striptags from "striptags";
import Layout from "./Layout";

import axios from "axios";

import ErrorJSON from "../utils/Error";

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
    };
    this.registerAction = this.registerAction.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val) });
  }

  registerAction(event) {
    event.preventDefault();

    this.setState({ errors: false });

    if (this.state.email.includes("@accenture.com")) {
      this.setState({
        errors:
          "Accenture people do not have to register using this form. Please sign in with your Accenture account on the login page.",
      });
      return;
    }
    if (this.state.password != this.state.password_2) {
      this.setState({ errors: "Passwords do not match." });
      return;
    }

    axios
      .post("/platform/public/register", {
        ...this.state,
      })
      .then((res) => {
        this.setState({ success: true });
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <Card className="card-register">
          <CardBody>
            <CardTitle className="big-card-title">Register</CardTitle>

            <p className="text-register">
            If you are part of Accenture, please use this <a href="/login">link</a> to login. 
            </p>

            <form onSubmit={this.registerAction} className="form-register">
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              {this.state.success ? (
                <Alert color="success">
                  Your request was successfully sent. An adminstrator will
                  review it and you will receive a confirmation email as soon as
                  it is validated.
                </Alert>
              ) : (
                <span></span>
              )}
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="name">First name * </Label>
                    <Input
                      type="text"
                      name="first_name"
                      id="first_name"
                      maxLength={60}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="name">Last name *</Label>
                    <Input
                      type="text"
                      name="last_name"
                      id="last_name"
                      maxLength={60}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">Email *</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">Job title *</Label>
                    <Input
                      type="text"
                      name="job_title"
                      id="job_title"
                      maxLength={60}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="name">Password *</Label>
                    <Input
                      type="password"
                      name="password"
                      id="pwd"
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">Confirm password *</Label>
                    <Input
                      type="password"
                      name="password_2"
                      id="password_2"
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="name">Company/Business organization *</Label>
                    <Input
                      type="text"
                      name="business_org"
                      id="business_org"
                      maxLength={60}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">City *</Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      maxLength={60}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              {this.state.success ? (
                <span></span>
              ) : (
                <Button type="submit" className="center-btn">
                  Register
                </Button>
              )}
            </form>
          </CardBody>
        </Card>
      </Layout>
    );
  }
}

export default Register;
