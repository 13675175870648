import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
  CardText,
} from "reactstrap";
import industries from "../../../variables/industries.json";
import geographies from "../../../variables/geographies.json";
import channels from "../../../variables/channels.json";
import types from "../../../variables/types.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EmailAccessibility from "./../blocks/EmailAccessibility";
import { CheckBlockPresence } from "./report";
class AccessibilityTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var task = this.props.task;
    var token = this.props.token;
    var editRef = this.props.editRef;
    var access = this.props.access;
    return (
      <Row>
        <Col sm="12">
          {!task.analysis ? (
            <span></span>
          ) : (
            <>
              {!access.includes("accessibility") ||
              task.reports.indexOf("accessibility") === -1 ? (
                <div className="mt-4 px-4">
                  <CheckBlockPresence analysis={task.analysis} block="vco">
                    <Row>
                     
                      <Col xs="12 fade-in" className="disabled-grayscale">
                        <Row>
                          <div className="text-center mt-3 mb-3 w-100">
                            <h3 className="warning">
                              This functionnality is not yet available, here is
                              a sample report. To get access, please contact{" "}
                              <a href="mailto:crisp.team@accenture.com">
                                crisp.team@accenture.com
                              </a>
                              . Thank you!
                            </h3>
                          </div>
                        </Row>
                        <Row>
                          <img
                            top
                            width="100%"
                            className="card-img-results "
                            src={"/img/accessibility-sample.png"}
                            alt="Card image cap"
                          />
                        </Row>
                      </Col>
                    </Row>
                  </CheckBlockPresence>
                </div>
              ) : (
                <>
                  <Row className="mt-4 px-4">
                    
                    <Col xs="12 fade-in">
                      <CheckBlockPresence
                        analysis={task.analysis}
                        block="email_accessibility"
                      >
                        <EmailAccessibility
                          id={task._id}
                          data={task.analysis.email_accessibility}
                          token={token}
                        ></EmailAccessibility>
                      </CheckBlockPresence>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default AccessibilityTab;
