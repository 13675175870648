import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";
import {Cube} from "../tabs/report"
class ImageEmotions extends React.Component {
  constructor(props) {
    super(props);
    this.generateScore();
  }

  generateScore() {
    let scorefinal =
      this.props.data.results.correct_emotion.results.score * 100;
    console.log("scorefinal", scorefinal);

    this.props.generateScore(scorefinal);
  }

  render() {
    return (
      <>
        {this.props.data.results.number_humans.results > 0 ? (
          <>
            <Row className="mt-2">
              <Col className="py-2 text-title" xs="4">
                Emotions
              </Col>
              <Col className="py-2 text-title " xs="6"></Col>
              <Col className="py-2 text-title" xs="2">
                Details
              </Col>
            </Row>
            <Row className="border-row no-top-border">
              <Col xs="4" className="py-2">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_emotion.results
                          .correct_anger
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Level of anger</Col>
                </Row>
              </Col>
              <Col xs="6" className="py-2">
                <Row>
                  <Col xs="6">
                    <div>Anger</div>
                  </Col>
                  <Col xs="6">
                    <div className="text-right">
                      {Math.round(
                        this.props.data.results.correct_emotion.results
                          .averageScoreAnger
                      )}
                      %
                    </div>
                  </Col>
                  <Progress
                    className="w-100 mx-3"
                    value={Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreAnger
                    )}
                    color={
                      this.props.data.results.correct_emotion.results
                        .averageScoreAnger > 50
                        ? "info"
                        : "success"
                    }
                  >
                    {Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreAnger
                    )}
                    %
                  </Progress>
                </Row>
              </Col>
              <Col xs="2" className="py-2">
                {this.props.data.results.correct_emotion.results
                  .correct_anger ? (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_min_anger
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_max_anger
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_min_anger
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_max_anger
                    }
                  </div>
                )}
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-2">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_emotion.results
                          .correct_joy
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Level of joy</Col>
                </Row>
              </Col>
              <Col xs="6" className="py-2">
                <Row>
                  <Col xs="6">
                    <div>Joy</div>
                  </Col>
                  <Col xs="6">
                    <div className="text-right">
                      {Math.round(
                        this.props.data.results.correct_emotion.results
                          .averageScoreJoy
                      )}
                      %
                    </div>
                  </Col>
                  <Progress
                    className="w-100 mx-3"
                    value={Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreJoy
                    )}
                    color={
                      this.props.data.results.correct_emotion.results
                        .averageScoreJoy < 50
                        ? "info"
                        : "success"
                    }
                  >
                    {Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreJoy
                    )}
                    %
                  </Progress>
                </Row>
              </Col>
              <Col xs="2" className="py-2">
                {this.props.data.results.correct_emotion.results.correct_joy ? (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_min_joy
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_max_joy
                    }{" "}
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_min_joy
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_max_joy
                    }
                  </div>
                )}
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-2">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_emotion.results
                          .correct_sorrow
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Level of sorrow</Col>
                </Row>
              </Col>
              <Col xs="6" className="py-2">
                <Row>
                  <Col xs="6">
                    <div>Sorrow</div>
                  </Col>
                  <Col xs="6">
                    <div className="text-right">
                      {Math.round(
                        this.props.data.results.correct_emotion.results
                          .averageScoreSorrow
                      )}
                      %
                    </div>
                  </Col>
                  <Progress
                    className="w-100 mx-3"
                    value={Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreSorrow
                    )}
                    color={
                      this.props.data.results.correct_emotion.results
                        .averageScoreSorrow > 50
                        ? "info"
                        : "success"
                    }
                  >
                    {Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreSorrow
                    )}
                    %
                  </Progress>
                </Row>
              </Col>
              <Col xs="2" className="py-2">
                {this.props.data.results.correct_emotion.results
                  .correct_sorrow ? (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_min_sorrow
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_max_sorrow
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_min_sorrow
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_max_sorrow
                    }
                  </div>
                )}
              </Col>
            </Row>

            <Row className="border-row">
              <Col xs="4" className="py-2">
                <Row>
                  <Col xs="2">
                    <Cube
                      color={
                        this.props.data.results.correct_emotion.results
                          .correct_surprise
                      }
                    ></Cube>
                  </Col>
                  <Col xs="10">Level of surprise</Col>
                </Row>
              </Col>
              <Col xs="6" className="py-2">
                <Row>
                  <Col xs="6">
                    <div>Surprise</div>
                  </Col>
                  <Col xs="6">
                    <div className="text-right">
                      {Math.round(
                        this.props.data.results.correct_emotion.results
                          .averageScoreSurprise
                      )}
                      %
                    </div>
                  </Col>
                  <Progress
                    className="w-100 mx-3"
                    value={Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreSurprise
                    )}
                    color={
                      this.props.data.results.correct_emotion.results
                        .averageScoreSurpise > 50
                        ? "info"
                        : "success"
                    }
                  >
                    {Math.round(
                      this.props.data.results.correct_emotion.results
                        .averageScoreSurprise
                    )}
                    %
                  </Progress>
                </Row>
              </Col>
              <Col xs="2" className="py-2">
                {this.props.data.results.correct_emotion.results
                  .correct_surprise ? (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_min_surprise
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_pos_max_surprise
                    }
                  </div>
                ) : (
                  <div>
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_min_surprise
                    }{" "}
                    {
                      this.props.data.results.correct_emotion.results
                        .reco_neg_max_surprise
                    }
                  </div>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}



export default ImageEmotions;
