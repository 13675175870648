/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import striptags from "striptags";

import axios from "axios";
import ErrorJSON from "../../utils/Error";
import AddMember from "./AddMember";

class EditKey extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.state = {
      modal: false,
      quota: "",
      access: { api: false, adobe: false, chrome: false },
      enabled: false,
      key: "",
    };
  }

  toggle(row) {
    console.log("toggle");
    if (row.access) {
      var accesses = {
        api: row.access.includes("api"),
        adobe: row.access.includes("adobe"),
        chrome: row.access.includes("chrome"),
      };
      this.setState({
        modal: !this.state.modal,
        id: row._id,
        quota: row.quota,
        enabled: row.enabled,
        key: row.key,
        access: accesses,
      });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  }

  handleCheck(value) {
    this.setState((prevState) => {
      const access = prevState.access;
      access[value] = !access[value];
      return { access: access };
    });
  }

  handleChange(e) {
    let nam = e.target.name;
    let val = e.target.value;
    this.setState({ [nam]: striptags(val) });
  }

  handleEnable() {
    this.setState((prevState) => {
      return { enabled: !prevState.enabled };
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ errors: false });

    var _this = this;

    var access = Object.keys(this.state.access).filter(function (id) {
      return _this.state.access[id];
    });

    axios
      .post("/platform/admin/key/edit", {
        quota: this.state.quota.toString(),
        enabled: this.state.enabled.toString(),
        id: this.state.id,
        access: access,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          this.setState({ errors: err.message });
        } else {
          this.setState({ errors: err.response.data });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal centered={true} isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Create new key</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              {this.state.errors ? (
                <ErrorJSON json={this.state.errors}></ErrorJSON>
              ) : (
                <span></span>
              )}

              <FormGroup>
                <Label for="key">Key</Label>
                <Input
                  disabled
                  type="text"
                  name="key"
                  id="key"
                  value={this.state.key}
                />
              </FormGroup>
              <FormGroup>
                <Label for="key">Quota</Label>
                <Input
                  type="number"
                  name="quota"
                  id="quota"
                  min="-1"
                  max="10000"
                  value={this.state.quota}
                  required
                  onChange={this.handleChange}
                  placeholder="Value (-1 for unlimited)"
                />
              </FormGroup>

              <FormGroup check>
                <Label className="mr-3" check>
                  <Input
                    checked={this.state.enabled}
                    onChange={() => this.handleEnable()}
                    type="checkbox"
                  />{" "}
                  Enabled
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <div className="mb-1">Access</div>
              <br />
              <FormGroup check inline>
                <Label className="mr-3" check>
                  <Input
                    checked={this.state.access.adobe}
                    onChange={() => this.handleCheck("adobe")}
                    type="checkbox"
                  />{" "}
                  Adobe
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
                <Label className="mr-3" check>
                  <Input
                    checked={this.state.access.api}
                    onChange={() => this.handleCheck("api")}
                    type="checkbox"
                  />{" "}
                  API
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
                <Label className="mr-3" check>
                  <Input
                    checked={this.state.access.chrome}
                    onChange={() => this.handleCheck("chrome")}
                    type="checkbox"
                  />{" "}
                  Chrome
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default EditKey;
