import React, { Component } from "react";
import NavbarPublic from "./Navbar";
const Layout = (props) => {
  return (
    <div>
      <NavbarPublic location={props.location}></NavbarPublic>
      <div class="wrapper wrapper-full-page">
        <div class="full-page">
          <div class="container">{props.children}</div>
          <div
            class="full-page-background"
            style={{backgroundPosition: "center",
            backgroundSize: "cover",
            
              backgroundImage:
                "url(/img/bg.jpg)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
